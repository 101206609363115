import {
  Component,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Inject,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  PLATFORM_ID,
  TemplateRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { distinctUntilChanged, EMPTY, filter, map, Observable, pairwise, Subscription, throttleTime } from 'rxjs';

import {
  findScrollContainer,
  isPresent,
  isDeepEqual,
  LocalDate,
  quantityOfMonths,
  unsubscribe,
  toMonthId,
} from '@fcom/core/utils';
import { MediaQueryService } from '@fcom/common/services/media-query/media-query.service';
import { finShare } from '@fcom/rx';
import { ScrollService } from '@fcom/common/services';

import { Month, Day, DateSelection, CalendarViewModel } from '../../../utils/date.interface';
import { ButtonSize, ButtonTheme, IconButtonTheme, IconButtonSize } from '../../buttons';
import { CalendarService } from '../services/calendar.service';
import { TagTheme } from '../../tag';
import { CalendarNavigationType, CalendarNavigationEvent, DateRange } from '../interfaces';
import { areSelectedDatesChanged, getMonthByDate } from '../../../utils/date.utils';

// TODO: calendar header height should be read dynamic
const CALENDAR_HEADER_HEIGHT = 192;
@Component({
  selector: 'fcom-calendar',
  styleUrls: ['./calendar.component.scss'],
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CalendarService],
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  public readonly ButtonTheme = ButtonTheme;
  public readonly ButtonSize = ButtonSize;
  public readonly IconLibrary = IconLibrary;
  public readonly TagTheme = TagTheme;
  public readonly IconButtonTheme = IconButtonTheme;
  public readonly IconButtonSize = IconButtonSize;
  public readonly SvgLibraryIcon = SvgLibraryIcon;
  public readonly CalendarNavigationType = CalendarNavigationType;
  public readonly CalendarNavigationEvent = CalendarNavigationEvent;

  @ViewChild('calendarMonths') calendarMonths: ElementRef;
  @ViewChildren('tag', { read: ElementRef }) tags: QueryList<ElementRef>;

  /**
   * Ranges where dates should be disabled
   * An array which includes array of two LocalDates
   */
  @Input() disabledDateRanges?: DateRange[];

  /**
   * Starting day of the calendar
   * usually is TODAY
   */
  @Input({ required: true }) minDate: LocalDate;

  /**
   * End day of the calendar
   * usually is TODAY + 360 days
   */
  @Input({ required: true }) maxDate: LocalDate;

  /**
   * Initially preselected dates
   */
  @Input() selectedDates: DateSelection;

  /**
   * Ways for navigation through months
   * arrows -> left and right arrows (single of dual month view)
   * mixed -> arrows used on desktop view and scroll used on mobile
   */
  @Input() navigationType: CalendarNavigationType = CalendarNavigationType.MIXED;

  /**
   * select range or single date
   */
  @Input() isDateRange = false;

  /**
   * Amount of months to be visible
   */
  @Input() displayMonths: 1 | 2 = 2;

  /**
   * Show month tag buttons on top of calendar
   */
  @Input() showTags = false;

  /**
   * Scroll on first selectable date on Init
   */
  @Input() scrollOnInit = true;

  /**
   * month index to be navigated to
   */
  @Input() scrollToMonthIndex$: Observable<number> = EMPTY;

  /**
   * Object of translated and localized date labels
   * (e.g. month names, date formats)
   */
  @Input({ required: true }) dateLabels: any;

  /**
   * Object of translated UI labels
   * (e.g. error messages, instructions)
   */
  @Input({ required: true }) uiLabels: any;

  @Input() dayTemplate: TemplateRef<{ dayValue: Day; dayString: number }>;

  @Output() monthChange: EventEmitter<Month> = new EventEmitter();

  @Output() datesSelected: EventEmitter<DateSelection> = new EventEmitter();

  visibleMonthIndex: number;
  showEmptyWeeks: boolean;
  dataModel: CalendarViewModel;
  hoveredDate: LocalDate | null = null;

  private subscription: Subscription = new Subscription();

  constructor(
    private element: ElementRef,
    private calendarService: CalendarService,
    private mediaQueryService: MediaQueryService,
    private scrollService: ScrollService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.subscription.add(
      this.calendarService.dataModel$.subscribe((model) => {
        this.cd.detectChanges();

        const activeMonth =
          getMonthByDate(
            model.months,
            isPresent(model.selectedDates?.startDate) ? model.selectedDates?.startDate : model.focusDate
          ) ?? model.months[0];

        if (activeMonth.monthArrayIndex !== this.visibleMonthIndex) {
          this.monthChange.emit(activeMonth);
        }

        if (areSelectedDatesChanged(this.dataModel?.selectedDates, model.selectedDates)) {
          this.datesSelected.emit(model.selectedDates);
        }

        if (
          (isPresent(this.dataModel?.isMobile) && this.dataModel.isMobile !== model.isMobile) ||
          (isPresent(this.dataModel?.firstDate) &&
            !this.dataModel.firstDate.equals(model.firstDate) &&
            isPresent(model.focusDate))
        ) {
          // when changes screen or changes month focus, re-adjust focus
          const index = model.months.find((month) => month.id === toMonthId(model.focusDate))?.monthArrayIndex ?? 0;
          this.monthWillChange(index);
        }

        // adjust visibleMonthIndex
        this.visibleMonthIndex =
          model.months.find((month) => month.id === toMonthId(model.firstDate))?.monthArrayIndex ?? 0;

        // adjust focus
        this.adjustFocus();

        this.dataModel = model;
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.mediaQueryService
        .isBreakpoint$('tablet_down')
        .pipe(distinctUntilChanged(), finShare())
        .subscribe((isMobile) => {
          this.showEmptyWeeks = !isMobile;
          this.calendarService.changeCalendarView(isMobile);
        })
    );

    this.subscription.add(
      this.scrollToMonthIndex$.subscribe((scrollToIndex) => {
        if (isPresent(scrollToIndex) && isPresent(this.dataModel?.minDate)) {
          this.selectMonth(scrollToIndex);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calendarService.set(this.getUpdatedCalendarData(changes));
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      /**
       * Listens on calendarMonths scroll and act ONLY if it is mobile and navigationType is MIXED
       * and load more months when needed
       */
      this.subscription.add(
        this.scrollService
          .listen(this.calendarMonths)
          .pipe(
            filter(() => this.isVerticalScroll()),
            map(() => {
              const container = findScrollContainer(this.calendarMonths.nativeElement);
              const scrollTop = container.scrollTop;
              const containerHeight = container.clientHeight - CALENDAR_HEADER_HEIGHT;
              const scrollHeight = container.scrollHeight - CALENDAR_HEADER_HEIGHT;
              // calculate how far from the bottom the user is
              return scrollHeight - (scrollTop + containerHeight);
            }),
            pairwise(),
            filter(([first, second]) => second < first && second < 140),
            throttleTime(100)
          )
          .subscribe(() => {
            this.handleVerticalScrollMonths(this.dataModel?.months.length + this.dataModel?.displayMonths);
          })
      );
    }
  }

  /**
   * Arrow navigation
   * @param event
   * @param eventType CalendarNavigationEvent type
   */
  onNavigateEvent(event: Event, eventType: CalendarNavigationEvent): void {
    (event.currentTarget as HTMLElement).focus();

    switch (eventType) {
      case CalendarNavigationEvent.PREV:
        this.calendarService.navigateToMonth(this.dataModel.firstDate.minusMonths(1).firstDayOfMonth);
        break;
      case CalendarNavigationEvent.NEXT:
        this.calendarService.navigateToMonth(this.dataModel.firstDate.plusMonths(1).firstDayOfMonth);
        break;
    }
  }

  /**
   * Histogram bar click or tag click
   * @param monthIndex
   */
  selectMonth(monthIndex: number): void {
    const { minDate } = this.dataModel;

    if (this.isVerticalScroll()) {
      this.handleVerticalScrollMonths(monthIndex + 1);
    } else {
      // navigate to the month by setting firstDate
      this.calendarService.navigateToMonth(minDate.plusMonths(monthIndex).firstDayOfMonth);
    }
  }

  /**
   * Select a day
   * @param day that is selected
   */
  selectDay(day: Day): void {
    if (!day.disabled) {
      this.calendarService.selectDate(day.date);
    }
  }

  isRange(day: Day): boolean {
    const date = day.date;
    const { startDate, endDate } = this.dataModel.selectedDates;

    return (
      date.equals(startDate) ||
      date.equals(endDate) ||
      (date.gt(startDate) && date.lt(endDate)) ||
      (isPresent(startDate) && !isPresent(endDate) && date.gt(startDate) && date.lt(this.hoveredDate))
    );
  }

  isInside(day: Day): boolean {
    const date = day.date;
    const { startDate, endDate } = this.dataModel.selectedDates;
    return date.gt(startDate) && date.lt(endDate);
  }

  isHovered(day: Day): boolean {
    const date = day.date;
    const { startDate, endDate } = this.dataModel.selectedDates;
    return (
      this.isDateRange && isPresent(startDate) && !isPresent(endDate) && date.gt(startDate) && date.lt(this.hoveredDate)
    );
  }

  /**
   * Handle how months are displayed when it is vertical scroll
   * @param amount desired months
   */
  private handleVerticalScrollMonths(amount: number): void {
    const { months, minDate, maxDate } = this.dataModel;
    const maxMonths = quantityOfMonths(minDate, maxDate);
    const loadAmount = amount + 1 <= maxMonths ? amount + 1 : maxMonths;

    // month is already loaded, we need to adjust focus
    if (months.length >= loadAmount) {
      this.updateSelectedMonth(loadAmount - 1);
    } else {
      // desired month is not loaded, we need to load it first
      this.calendarService.loadMonths(loadAmount);
    }
  }

  private updateSelectedMonth(monthIndex: number): void {
    this.monthWillChange(monthIndex);

    if (isPresent(this.dataModel.months[monthIndex])) {
      this.monthChange.emit(this.dataModel.months[monthIndex]);
    }
  }

  /**
   * This combines the two scroll methods for month change
   * one is the actual scroll to the needed month when navigationType is MIXED
   * other one is the scroll of the month chips if enabled
   */
  private monthWillChange(index: number): void {
    const { months } = this.dataModel;
    // this is used only by the tags
    this.visibleMonthIndex = index;

    if (this.isVerticalScroll() && index >= 0 && index <= months.length - 1) {
      this.scrollToMonth(index);
    }

    if (this.showTags) {
      this.scrollToMonthChip(index);
    }
  }

  private scrollToMonthChip(index: number): void {
    this.tags?.get(index)?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }

  private scrollToMonth(index: number): void {
    this.scrollService.smoothScroll(this.calendarMonths.nativeElement.children[index], {
      offsetY: CALENDAR_HEADER_HEIGHT,
    });
  }

  /**
   * Helper method to determine if navigation type is MIXED and it is mobile
   * @returns
   */
  private isVerticalScroll(): boolean {
    return !!(this.dataModel?.isMobile && this.dataModel?.navigationType === CalendarNavigationType.MIXED);
  }

  private adjustFocus(): void {
    this.element.nativeElement.querySelector('button.day[tabindex="0"]')?.focus();
  }

  /**
   * Gets updated values for desired Inputs and creates a Partial<CalendarViewModel>
   * @param changes SimpleChanges
   * @returns updated desired Inputs
   */
  private getUpdatedCalendarData(changes: SimpleChanges): Partial<CalendarViewModel> {
    return [
      'displayMonths',
      'isDateRange',
      'scrollOnInit',
      'showTags',
      'minDate',
      'maxDate',
      'disabledDateRanges',
      'selectedDates',
      'dateLabels',
      'uiLabels',
      'navigationType',
    ]
      .filter((option) => option in changes)
      .reduce((data: Partial<CalendarViewModel>, option: string) => {
        if (
          !isDeepEqual(changes[option].currentValue, changes[option].previousValue) &&
          !isDeepEqual(this.dataModel?.[option], changes[option].currentValue)
        ) {
          if (option === 'uiLabels') {
            data['selectedLabel'] = changes[option].currentValue?.['selected'];
          } else {
            data[option] = changes[option].currentValue;
          }
        }
        return data;
      }, {});
  }
}
