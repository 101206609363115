<div
  *ngIf="isAm === false || (amDestination$ | finMap: 'holidayType' | async) === AmHolidayType.DP"
  class="dates-selector-root relative fill grow"
  [class.separator]="!showSingular && (isOneway$ | async) === false"
>
  <ng-container *ngIf="!showSingular">
    <fin-booking-widget-selector
      [disabled]="disabled"
      (click)="openModal()"
      [double]="
        (isOneway$ | async) === false
          ? {
              first: {
                label: 'bookingSearch.boundSelection.departureDate' | finLocalization,
                placeholder: 'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization,
                filled: (travelDates$ | async)?.['departureDate'] !== undefined,
              },
              second: {
                label: 'bookingSearch.boundSelection.returnDate' | finLocalization,
                placeholder: 'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization,
                filled: (travelDates$ | async)?.['returnDate'] !== undefined,
              },
            }
          : undefined
      "
      [label]="'bookingSearch.boundSelection.departureDate' | finLocalization"
      [placeholder]="'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization"
      [filled]="(travelDates$ | async)?.['departureDate'] !== undefined"
      [attr.data-testid]="'travel-dates-selector-both'"
      [highLight$]="highLight$"
    >
      <span
        *ngIf="(isOneway$ | async) === true && (travelDates$ | async)?.['departureDate'] as selectedDate"
        data-testid="departure-date"
        >{{ selectedDate | finDateFormat: 'FULL_NO_YEAR' }}</span
      >
      <span first *ngIf="(travelDates$ | async)?.['departureDate'] as selectedDate" data-testid="departure-date">{{
        selectedDate | finDateFormat: 'FULL_NO_YEAR'
      }}</span>
      <div
        separator
        *ngIf="(isOneway$ | async) === false && showSeparator"
        class="flex flex--center flex--self-center absolute white-bg date-spacer ps-xxsmall z1"
        [class.nordic-blue-50-bg]="highLight$ | async"
      >
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.MINUS"></fcom-icon>
      </div>
      <span second *ngIf="(travelDates$ | async)?.['returnDate'] as selectedDate" data-testid="return-date">{{
        selectedDate | finDateFormat: 'FULL_NO_YEAR'
      }}</span>
    </fin-booking-widget-selector>
  </ng-container>
  <fin-booking-widget-selector
    *ngIf="showSingular as departureOrReturn"
    [disabled]="disabled"
    (click)="openModal()"
    [label]="'bookingSearch.boundSelection.' + departureOrReturn | finLocalization"
    [placeholder]="'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization"
    [filled]="!!(travelDates$ | async)?.[departureOrReturn]"
    [attr.data-testid]="'travel-dates-selector-' + departureOrReturn"
  >
    <span *ngIf="(travelDates$ | async)?.[departureOrReturn] as selectedDate" data-testid="departure-date">{{
      selectedDate | finDateFormat: 'FULL_NO_YEAR'
    }}</span>
  </fin-booking-widget-selector>
</div>

<div *ngIf="(amDestination$ | finMap: 'holidayType' | async) === AmHolidayType.AM">
  <div class="df-laptop-up">
    <fin-am-duration-selector
      [selectedDuration]="selectedDuration$ | async"
      [durationOptions]="amDestination$ | finMap: 'holidayTypeItem.items' | async"
      (setDuration)="selectDuration($event)"
      class="flex flex--basis-half border-right"
    ></fin-am-duration-selector>
    <fin-booking-widget-selector
      class="flex flex-1"
      [disabled]="disabled"
      [position]="'right'"
      [label]="'bookingSearch.boundSelection.departureDate' | finLocalization"
      [attr.data-testid]="'travel-dates-selector-am'"
      (click)="openModal()"
    >
      <span *ngIf="(travelDates$ | async)?.departureDate as departureDate" data-testid="departure-date">{{
        departureDate | finDateFormat: 'FULL_NO_YEAR'
      }}</span>
    </fin-booking-widget-selector>
  </div>
</div>

<fcom-modal
  [(open)]="modalOpen"
  [fitToContent]="true"
  [buttons]="null"
  [fullHeight]="true"
  [extraWide]="true"
  data-testid="travel-dates-modal"
>
  <fcom-datepicker
    *ngIf="modalOpen"
    [uiLabels]="'datepicker' | finLocalization"
    [dateLabels]="'date' | finLocalization"
    [titleAreaConfiguration]="{
      titleAreaStyle: 'ps-large-x ps-xsmall-y',
      title: datePickerTitleLabel$ | async | finLocalization,
      titleStyle: 'font-heading-4',
    }"
    [isDateRange]="(isOneway$ | async) === false"
    [selectableDatesRange]="calendarRange"
    [disabledDateRanges]="disabledDateRanges$ | async"
    [selectedDates]="[(travelDates$ | async)?.departureDate, (travelDates$ | async)?.returnDate]"
    [doneBtnHidden]="true"
    [showTags]="false"
    [headerTemplate]="(prices$ | async)?.histogram?.length > 0 ? calendarHeader : undefined"
    [dayTemplate]="dayTemplate"
    [scrollToMonthIndex$]="scrollToHistogramMonth$"
    [showAddReturn]="showAddReturn"
    (selectedDatesChange)="updateCalendarDates($event)"
    (close)="closeModal()"
    (monthChange)="setHistogramMonth($event.monthArrayIndex)"
    (addReturn)="addReturn.emit()"
  ></fcom-datepicker>

  <!-- custom modal footer -->
  <div
    class="df-tablet-up flex--middle ps-large-x grey-50-bg font-body-1 ps-small"
    [ngClass]="{
      'travel-dates-sticky-footer': datesSelected$ | async,
    }"
    data-testid="travel-dates-footer"
  >
    <span
      class="flex flex--space-between fill travel-dates-footer-container"
      [ngClass]="{
        'flex--align-start': showPerAdultPrice,
        'flex--align-end': !showPerAdultPrice,
      }"
    >
      <ng-container *ngIf="isAm === false">
        <span *ngIf="showPerAdultPrice" data-testid="date-modal-price">
          <span
            >{{
              ((isOneway$ | async)
                ? 'bookingWidget.priceCalendar.oneWayPerAdult'
                : 'bookingWidget.priceCalendar.roundTripPerAdult'
              ) | finLocalization
            }}
          </span>
          <span class="bold-type">{{
            pricePerAdult$ | async | finPrice: { stripZeroCents: true, useSymbol: true } | finEmpty: '&ndash;'
          }}</span>
        </span>
        <span class="flex--end" *ngIf="showFlexibleDatesSelection">
          <fcom-checkbox
            [name]="'flexibleDates'"
            [checked]="flexibleDates"
            [disabled]="false"
            (change)="setFlexibleDates.emit($event)"
            data-testid="flexible-dates"
          >
            <span>{{ 'priceCalendar.flexibleDates' | finLocalization }}</span>
          </fcom-checkbox>
        </span>
      </ng-container>
      <span class="flex flex--middle" *ngIf="(amDestination$ | finMap: 'holidayType' | async) === AmHolidayType.AM">
        <fcom-icon class="icon-medium ms-xsmall-r" [name]="SvgLibraryIcon.AM_LOGO"></fcom-icon>
        <span>{{ 'aurinkomatkat.calendar.recommendedTravelDates' | finLocalization }}</span>
      </span>
    </span>
    <div class="done-button align-center">
      <fcom-button
        [text]="'actions.done' | finLocalization"
        (clickBtn)="closeModal()"
        data-testid="date-modal-close-btn"
      ></fcom-button>
    </div>
  </div>
</fcom-modal>

<ng-template #calendarHeader>
  <fin-histogram
    [barData$]="prices$ | finMap: 'histogram'"
    [selected$]="selectedHistogramMonth$"
    [isActive$]="modalOpen | finToObservable"
    (barSelected)="handleHistogramClick($event)"
    (barsScrolled)="handleBarsScrolled($event)"
  ></fin-histogram>
</ng-template>

<ng-template #dayTemplate let-dayValue="dayValue" let-dayString="dayString">
  <div
    class="calendar-day"
    *finLet="(prices$ | async)?.calendar?.[dayValue.id] as price"
    [attr.data-testid]="'calendar-day-' + dayValue.id"
  >
    <div
      class="ps-xxsmall-t"
      [ngClass]="{ 'aurinkomatkat-orange-text': (suggestedAmTravelDays$ | async)?.includes(dayValue.id) }"
    >
      {{ dayString }}
    </div>
    <div
      *ngIf="(prices$ | async)?.calendar"
      class="calendar-day-price font-body-2 regular-type"
      [ngClass]="{
        'heather-700-text bold-type': price?.isLowestPrice,
      }"
      [attr.data-testid]="'calendar-price-' + dayValue.id"
    >
      <ng-container
        *ngTemplateOutlet="
          priceTemplate;
          context: {
            amount:
              dayValue.id >= (travelDates$ | async)?.departureDate?.id &&
              dayValue.id < (travelDates$ | async)?.returnDate?.id &&
              (isOneway$ | async) === false
                ? undefined
                : price?.amount,
          }
        "
      ></ng-container>
    </div>
    <ng-template #priceTemplate let-amount="amount">
      {{ amount | finPrice: { abbreviate: true, useCustomFormat: true } | finEmpty: '&nbsp;' }}
    </ng-template>

    <div
      *ngIf="price"
      class="day-border fill absolute"
      [class.birch-300-bg]="!price?.noFlight"
      [class.grey-400-bg]="price?.noFlight"
      [attr.data-testid]="'calendar-price-border-' + dayValue.id"
    ></div>
  </div>
</ng-template>
