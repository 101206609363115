<ng-template #buttonTmpl>
  <div class="nordic-blue-25-bg">
    <ng-content select="[buttons]"></ng-content>
  </div>
</ng-template>

<div class="drawer-overlay" *ngIf="showDrawer$ | async" @overlayInOut></div>
<dialog
  *ngIf="showDrawer$ | async"
  (fcomClickOutside)="open = false"
  class="drawer flex flex--column no-outline border-none"
  [class.rounded-top-large]="direction === DrawerDirection.FROM_BOTTOM"
  [attr.aria-labelledby]="drawerTitleId"
  [attr.aria-describedby]="drawerDescriptionId"
  [ngClass]="direction"
  fcomTrapFocus
  [@sliderInOut]="direction"
>
  <header
    class="modal-header border-bottom flex--nogrow flex--basis-header flex flex--row flex--center fill relative white-bg"
  >
    <h2
      #drawerHeading
      [id]="drawerTitleId"
      data-testid="drawer-title"
      class="font-heading-3 flex-1 truncate overflow-hidden nordic-blue-900-text pr-large-x pr-xsmall-y"
      tabindex="-1"
    >
      {{ title }}
    </h2>
    <fcom-icon-button
      class="close-button flex--nogrow flex--self-stretch pr-large-x pr-xsmall-y"
      [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.CLOSE_DELETE }"
      [theme]="IconButtonTheme.DARK_PRIMARY_NORDIC_BLUE"
      [aria]="{ label: ariaClose }"
      [title]="ariaClose"
      (clickBtn)="open = false"
    ></fcom-icon-button>
  </header>
  <div class="flex flex-1 scroll" [attr.id]="drawerDescriptionId" data-testid="drawer-content">
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="showButtons">
    <ng-container *ngTemplateOutlet="buttonTmpl"></ng-container>
  </ng-container>
</dialog>
