import { NgModule, ModuleWithProviders } from '@angular/core';

import { CurrencyService } from '@fcom/core';

import { FormatPassengerPipe } from './format-passenger.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { DelayPipe } from './delay.pipe';
import { EmptyPipe } from './empty.pipe';
import { FinLowerCasePipe } from './fin-lower-case.pipe';
import { MapPipe } from './map.pipe';
import { MsgFormatPipe } from './msg-format.pipe';
import { ObservableToArrayPipe } from './observe.pipe';
import { PadPipe } from './pad.pipe';
import { PricePipe } from './price.pipe';
import { SafePipe } from './safe.pipe';
import { AddedServicesPipe } from './added-services.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { TailNamesPipe } from './tail-names.pipe';
import { TimeFormatPipe } from './time.pipe';
import { NegatePipe } from './negate.pipe';
import { IntlJoinPipe } from './intl-join.pipe';
import { JoinPipe } from './join.pipe';
import { UniquePipe } from './unique.pipe';
import { TemporaryDurationPipe } from './duration.temp.pipe';
import { ToObservablePipe } from './to-observable.pipe';
import { MultiplyAmountPipe } from './multiply-amount.pipe';
import { DurationPipe } from './duration.pipe';
import { OffersPricePipe } from './offers-price.pipe';
import { CountryNamePipe } from './country-name.pipe';
import { PointsPipe } from './points.pipe';
import { TimestampPipe } from './timestamp.pipe';
import { EncodePipe } from './encode-string.pipe';
import { ResolveLinksPipe } from './resolve-links.pipe';
import { NonZeroAmountPipe } from './non-zero-amount.pipe';
import { WeekdayPipe } from './weekday.pipe';
import { IncludesPipe } from './includes.pipe';
import { FormatCardNumberPipe } from './format-card-number.pipe';

@NgModule({
  declarations: [
    CountryNamePipe,
    DateFormatPipe,
    DelayPipe,
    DurationPipe,
    EmptyPipe,
    FormatPassengerPipe,
    IntlJoinPipe,
    JoinPipe,
    MapPipe,
    MsgFormatPipe,
    MultiplyAmountPipe,
    NegatePipe,
    ObservableToArrayPipe,
    PadPipe,
    PricePipe,
    OffersPricePipe,
    SafePipe,
    AddedServicesPipe,
    StripHtmlPipe,
    TailNamesPipe,
    TimeFormatPipe,
    ToObservablePipe,
    UniquePipe,
    TemporaryDurationPipe,
    FinLowerCasePipe,
    PointsPipe,
    TimestampPipe,
    EncodePipe,
    ResolveLinksPipe,
    NonZeroAmountPipe,
    WeekdayPipe,
    IncludesPipe,
    FormatCardNumberPipe,
  ],
  exports: [
    CountryNamePipe,
    DateFormatPipe,
    DelayPipe,
    DurationPipe,
    EmptyPipe,
    FormatPassengerPipe,
    IntlJoinPipe,
    JoinPipe,
    MapPipe,
    MsgFormatPipe,
    MultiplyAmountPipe,
    NegatePipe,
    ObservableToArrayPipe,
    PadPipe,
    PricePipe,
    OffersPricePipe,
    SafePipe,
    AddedServicesPipe,
    StripHtmlPipe,
    TailNamesPipe,
    TimeFormatPipe,
    ToObservablePipe,
    UniquePipe,
    TemporaryDurationPipe,
    FinLowerCasePipe,
    PointsPipe,
    TimestampPipe,
    EncodePipe,
    ResolveLinksPipe,
    NonZeroAmountPipe,
    WeekdayPipe,
    IncludesPipe,
    FormatCardNumberPipe,
  ],
})
export class PipesModule {
  static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule,
      providers: [CurrencyService],
    };
  }
}
