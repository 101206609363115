import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isPlatformServer } from '@angular/common';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, take, map, filter } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { SelectOption } from '@fcom/common';
import { stopPropagation } from '@fcom/core/utils';
import { WindowRef } from '@fcom/core/providers';

import { IconButtonSize, IconButtonTheme } from '../../buttons';
import { SelectInputComponent } from '../select-input/select-input.component';
import { TextInputComponent } from '../text-input/text-input.component';
import { CursorType } from '../enums';

const BOTTOM_PADDING = 20;
const MIN_NUM_OF_ITEMS_IN_VIEW = 2;

@Component({
  selector: 'fin-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboBoxComponent extends SelectInputComponent implements AfterViewInit, AfterViewChecked {
  readonly CursorType = CursorType;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  searchable = false;

  @Input()
  searchLabel: string;

  @Input()
  clearSearchLabel: string;

  @Input()
  infoText: string;

  @Input()
  optionTemplate?: TemplateRef<{ name: string; value: string }>;

  selectedOption: SelectOption;
  searchForm: UntypedFormGroup;
  filteredOptions: SelectOption[];
  dropDownStyle: { 'max-height': string };

  dropDownOpen$ = new BehaviorSubject<boolean>(false);
  activeIndex$ = new BehaviorSubject<number>(0);
  searchEnabled$ = new BehaviorSubject<boolean>(false);
  bottomPosition$ = new BehaviorSubject<number>(0);

  @ViewChild('dropDown')
  dropDown: ElementRef;

  @ViewChild('selectButton')
  selectButton: ElementRef;

  @ViewChild('buttonContainer')
  buttonContainer: ElementRef;

  @ViewChild('searchInput')
  searchInput: TextInputComponent;

  @ViewChild('optionsList')
  optionsList: ElementRef;

  @Output()
  dropDownOpen: EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (
      !this.dropDown.nativeElement.contains(event.target) &&
      !event.composedPath().includes(this.buttonContainer.nativeElement)
    ) {
      this.closeDropDown();
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    private windowRef: WindowRef,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    super();

    this.searchForm = this.fb.group({
      searchInput: this.fb.control(''),
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platform)) {
      this.dropDownStyle = {
        'max-height': '0px',
      };
      return;
    }

    this.subscriptions.add(
      this.ctrlField.valueChanges.subscribe((value) => {
        if (!value) {
          this.selectedOption = undefined;
          this.activeIndex$.next(0);
        } else {
          this.selectOptionByValue(this.ctrlField.value);
        }
      })
    );

    // select initial value
    if (this.ctrlField.value) {
      this.selectOptionByValue(this.ctrlField.value);
    }

    this.subscriptions.add(
      this.searchForm.controls['searchInput'].valueChanges.subscribe((value) => {
        if (value && !this.dropDownOpen$.getValue()) {
          this.openDropDown();
        }
      })
    );

    this.subscriptions.add(
      this.activeIndex$.subscribe((activeIndex) => {
        this.optionsList?.nativeElement.children[activeIndex]?.scrollIntoView?.({
          block: 'nearest',
          behaviour: 'smooth',
        });
      })
    );

    // Debounce to get the final (bottom) position of the button container for the dropdown
    this.subscriptions.add(
      this.bottomPosition$.pipe(distinctUntilChanged(), debounceTime(100), take(1)).subscribe((bottomPosition) => {
        const optionMultiplier =
          this.options && this.options.length >= MIN_NUM_OF_ITEMS_IN_VIEW ? MIN_NUM_OF_ITEMS_IN_VIEW : 1;
        const optionHeight = this.optionsList?.nativeElement?.children[0]?.offsetHeight ?? 0;
        const heightBasedOnMinNumOfOptions = optionHeight * optionMultiplier;
        const heightBasedOnBottomPosition = this.windowRef.nativeWindow.innerHeight - (bottomPosition + BOTTOM_PADDING);

        const maxHeight =
          heightBasedOnMinNumOfOptions > heightBasedOnBottomPosition
            ? heightBasedOnMinNumOfOptions
            : heightBasedOnBottomPosition;
        this.dropDownStyle = {
          'max-height': `${maxHeight}px`,
        };
      })
    );
  }

  ngAfterViewChecked(): void {
    if (isPlatformServer(this.platform)) {
      return;
    }

    if (!this.dropDownStyle) {
      const rect = this.buttonContainer.nativeElement.getBoundingClientRect();
      this.bottomPosition$.next(rect.bottom);
    }
  }

  selectOptionByValue(value: string): void {
    this.selectedOption = this.options.find((option) => option.value === value);

    if (this.searchable) {
      this.searchForm.controls.searchInput.setValue(this.selectedOption.name);
    }

    this.activeIndex$.next(this.options.indexOf(this.selectedOption));
  }

  selectOption(option: SelectOption): void {
    this.ctrlField.setValue(option.value);
    this.closeDropDown();
  }

  selectCurrentActiveOption(): void {
    this.subscriptions.add(
      combineLatest([this.activeIndex$, this.dropDownOpen$])
        .pipe(
          filter(([_activeIndex, dropDownOpen]) => dropDownOpen),
          map(([activeIndex]) => activeIndex),
          take(1)
        )
        .subscribe((activeIndex) => {
          this.selectOption((this.filteredOptions || this.options)[activeIndex]);
        })
    );
  }

  closeDropDownOnFocusout(e: FocusEvent): void {
    if (!this.dropDown.nativeElement.contains(e.relatedTarget)) {
      this.closeDropDown();
    }
  }

  closeDropDown(): void {
    this.searchEnabled$.next(false);
    this.filteredOptions = undefined;

    this.searchForm.controls['searchInput'].setValue(this.selectedOption?.name || '');

    this.dropDownOpen$.next(false);
    this.dropDownOpen.emit(false);
  }

  openDropDown(): void {
    this.dropDownOpen$.next(true);

    this.filteredOptions = undefined;

    if (this.searchable) {
      this.searchEnabled$.next(true);
    }

    this.dropDownOpen.emit(true);
  }

  onInputEnter(): void {
    const dropDownOpen = this.dropDownOpen$.getValue();

    if (dropDownOpen) {
      this.selectCurrentActiveOption();
    } else {
      this.openDropDown();
    }
  }

  handleKeyDown(e: KeyboardEvent): void {
    const currentActiveIndex = this.activeIndex$.getValue();
    const opts = this.filteredOptions || this.options || [];
    const maxIndex = Math.max(0, opts.length - 1);

    const dropDownOpen = this.dropDownOpen$.getValue();
    switch (e.key) {
      case 'Escape':
        this.closeDropDown();
        break;
      case 'Down':
      case 'ArrowDown':
        stopPropagation(e);
        if (dropDownOpen) {
          this.activeIndex$.next(currentActiveIndex + 1 > maxIndex ? 0 : currentActiveIndex + 1);
        } else {
          this.openDropDown();
        }
        break;
      case 'Up':
      case 'ArrowUp':
        stopPropagation(e);
        if (dropDownOpen) {
          this.activeIndex$.next(currentActiveIndex - 1 < 0 ? maxIndex : currentActiveIndex - 1);
        } else {
          this.openDropDown();
          this.activeIndex$.next(maxIndex);
        }
        break;
    }
  }

  handleSearch(): void {
    const query = this.searchForm.controls['searchInput'].value;

    this.filteredOptions = this.options.filter((option) => option.name?.toLowerCase().startsWith(query.toLowerCase()));
  }

  clearSearch(e: Event): void {
    this.searchForm.controls.searchInput.setValue('');
    this.closeDropDown();
    this.searchInput?.inputElement.nativeElement.focus();
    e.stopPropagation();
  }
}
