import { Inject, Injectable, OnDestroy, Optional, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { makeStateKey } from '@angular/platform-browser';

import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, map, take, tap } from 'rxjs/operators';

import { CookieService } from '@fcom/core/services';
import { StateTransferService } from '@fcom/core';
import { entrySet, unsubscribe } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';
import { LanguageService } from '@fcom/ui-translate';

import { GtmService } from '../../gtm';
import {
  AKAMAI_AB_SEGMENT_COOKIE,
  akamaiSegmentToNumericMap,
  GtmEvent,
  GtmMultivariateEvent,
  MANUAL_AB_SEGMENT_COOKIE,
  MULTIVARIATE_TEST_COOKIES,
  MultivariateTestConfiguration,
  MultivariateTestCookies,
  MultivariateTestId,
  Segment,
  TestVariant,
  TestVariantWithId,
  UserVariantSegment,
  VariantSource,
} from '../../interfaces';

const CONTROL_SEGMENT = 0 as Segment;

export const RUNNING_TESTS: MultivariateTestConfiguration[] = [
  {
    id: MultivariateTestId.EXAMPLE_TEST_PLACEHOLDER,
    requiresInjectScript: false,
    segments: {
      [TestVariant.A]: [CONTROL_SEGMENT],
      [TestVariant.B]: [2],
    },
  },
  {
    id: MultivariateTestId.LANDING_PAGE_FILTERS_AB_TEST,
    requiresInjectScript: false,
    segments: {
      [TestVariant.A]: [CONTROL_SEGMENT],
      [TestVariant.B]: [1],
    },
  },
];

export const generateSegmentResolver =
  (testId: MultivariateTestId, countryCode: string, runningTests: MultivariateTestConfiguration[]) =>
  (segment: Segment): TestVariant => {
    const test = runningTests.find((t) => t.id === testId);

    if (!test) {
      // If no test setup we always resolve to NOT_IN_TEST
      return TestVariant.NOT_IN_TEST;
    }

    if (test.includeCountries?.length && !test.includeCountries.includes(countryCode)) {
      return TestVariant.NOT_IN_TEST;
    }

    const variant = entrySet(test.segments).find((entry) => entry.value.includes(segment));

    if (!variant) {
      return TestVariant.NOT_IN_TEST;
    }

    const sameSegmentUsedForCountryTest = runningTests.some((test) => {
      if (!test.includeCountries?.length) {
        return false;
      }

      if (test.id === testId) {
        return false;
      }

      const testEntries = entrySet(test.segments);

      if (
        test.includeCountries.includes(countryCode) &&
        testEntries
          .filter((entry) => entry.key !== TestVariant.A)
          .some((entry) => entry.value.some((segment) => variant.value.includes(segment)))
      ) {
        return true;
      }

      return false;
    });

    if (sameSegmentUsedForCountryTest) {
      return TestVariant.NOT_IN_TEST;
    }

    return variant.key as TestVariant;
  };

@Injectable()
export class MultivariateTestService implements OnDestroy {
  segmentsFeed$: ReplaySubject<UserVariantSegment> = new ReplaySubject(1);

  reportedTestIds: MultivariateTestId[] = [];

  private subscriptions = new Subscription();

  constructor(
    private cookieService: CookieService,
    private gtmService: GtmService,
    private stateTransferService: StateTransferService,
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platform: object,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(MULTIVARIATE_TEST_COOKIES) private readonly multivariateTestCookies: MultivariateTestCookies
  ) {
    this.stateTransferService
      .wrapToStateCache(makeStateKey<UserVariantSegment>('ab-test-segment'), () => of(this.getSegmentFromCookies()))
      .pipe(take(1))
      .subscribe((value) => {
        const nextValue = value || { segment: 0, source: VariantSource.RANDOMIZED };
        this.segmentsFeed$.next(nextValue);

        this.injectAkamaiSegmentToBodyIfRequired(nextValue);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  /**
   * This is returning a subscription to segment, you need to provide your experiment
   * id and remember to unsubscribe.
   * It reports your id and the dynamic segment to gtmService (segment can change from initial after CAS response).
   * @param testId
   * @param variant
   */
  reportTest(testId: string, variant: string): void {
    if (isPlatformServer(this.platform)) {
      return;
    }

    this.subscriptions.add(
      this.segmentsFeed$
        .pipe(distinctUntilKeyChanged('source'), distinctUntilKeyChanged('segment'))
        .subscribe((segment) => {
          const eventData: GtmMultivariateEvent = {
            testId,
            segment: segment.segment,
            source: segment.source,
            variant,
          };

          this.gtmService.pushEventToDataLayer(GtmEvent.MULTIVARIATE_TEST, eventData);
        })
    );
  }

  getTestVariant(
    id: MultivariateTestId,
    autoReportTest = true,
    skipCountryCheck = false
  ): Observable<TestVariantWithId> {
    const variant$ = combineLatest([
      this.segmentsFeed$,
      skipCountryCheck ? of('int') : this.languageService.countryCode,
    ]).pipe(
      map(([userVariantSegment, countryCode]) => {
        const segmentResolver = generateSegmentResolver(id, countryCode, RUNNING_TESTS);
        const variant = segmentResolver(userVariantSegment.segment);
        return { variant, id };
      })
    );

    if (autoReportTest && !isPlatformServer(this.platform)) {
      return variant$.pipe(
        tap(({ id, variant }) => {
          if (variant !== TestVariant.NOT_IN_TEST && !this.reportedTestIds.includes(id)) {
            this.reportTest(id, variant);
            this.reportedTestIds.push(id);
          }
        }),
        finShare()
      );
    }

    return variant$;
  }

  // This is here because service is injected to app component, and needs a method call not to crash linters
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init(): void {}

  private getCookieValue(name: string): any {
    if (isPlatformServer(this.platform)) {
      return this.multivariateTestCookies[name];
    }
    return this.cookieService.getItem(name);
  }

  private getSegmentFromCookies(): UserVariantSegment {
    const manualSegment = this.getCookieValue(MANUAL_AB_SEGMENT_COOKIE);

    if (manualSegment) {
      return {
        segment: Number(manualSegment) as Segment,
        source: VariantSource.MANUAL,
      };
    }
    return {
      segment: akamaiSegmentToNumericMap[this.getCookieValue(AKAMAI_AB_SEGMENT_COOKIE)] || 0,
      source: VariantSource.AKAMAI,
    };
  }

  private injectAkamaiSegmentToBodyIfRequired(variantSegment: UserVariantSegment): void {
    if (RUNNING_TESTS.some((test) => test.requiresInjectScript)) {
      const script = this.document.createElement('script');
      script.innerHTML = `(function (d) {
          d.body.setAttribute('data-segment', ${variantSegment.segment});
        })(document);`;
      this.document.body.appendChild(script);
    }
  }
}
