<div
  [ngClass]="{
    'nordic-blue-900-text': (enableBenefitChanges$ | async) === false || benefit.isPositive,
    'grey-500-text': (enableBenefitChanges$ | async) && !benefit.isPositive,
  }"
  data-testid="benefit"
>
  <div
    class="fill flex flex--row flex--top"
    [ngClass]="{
      'pr-xxsmall-t': (enableBenefitChanges$ | async) === false,
      'ps-xsmall-t': (enableBenefitChanges$ | async) === true,
    }"
  >
    <div class="flex--nogrow ms-xsmall-r">
      <fcom-icon class="icon-medium" [name]="benefitWithIcon.icon"></fcom-icon>
    </div>
    <div class="flex-1">
      <div
        [class.heather-700-text]="isKoreanColor"
        class="font-body-1"
        [ngClass]="{
          'medium-type': (enableBenefitChanges$ | async) === false || benefit.isPositive,
          'regular-type': (enableBenefitChanges$ | async) === true && !benefit.isPositive,
        }"
      >
        {{ benefitWithIcon.label }}
      </div>
      <div
        class="regular-type"
        *ngIf="!hasChildren"
        [ngClass]="{
          'font-body-1': (enableBenefitChanges$ | async) === false,
          'font-body-2': (enableBenefitChanges$ | async) === true,
        }"
      >
        {{ benefitWithIcon.value }}
      </div>
    </div>
  </div>
  <div *ngIf="hasChildren">
    <ng-container *ngFor="let childBenefit of benefitWithIcon.children">
      <div
        class="fill flex flex--row flex--top ps-large-l ps-xxsmall-y"
        *ngIf="childBenefit.isPositive"
        [ngClass]="{ 'ms-xsmall-y': (enableBenefitChanges$ | async) === false }"
      >
        <div class="flex--nogrow ms-xsmall-r">
          <fcom-icon class="icon-medium" [name]="childBenefit.icon"></fcom-icon>
        </div>
        <div class="flex-1">
          <div
            class="medium-type"
            [ngClass]="{
              'font-body-1': (enableBenefitChanges$ | async) === false,
              'font-body-2': (enableBenefitChanges$ | async) === true,
            }"
          >
            {{ childBenefit.label }}
          </div>
          <div
            class="regular-type"
            [ngClass]="{
              'font-body-1': (enableBenefitChanges$ | async) === false,
              'font-body-2': (enableBenefitChanges$ | async) === true,
            }"
          >
            {{ childBenefit.value }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
