<div
  class="booking-entry-widget-root flex flex--column relative white-bg pr-medium rounded-large"
  #widgetRoot
  [@smoothGrow]="{ value: showCompact$ | async, params: { startHeight: (rootContainerStartHeight$ | async) } }"
  (click)="startHotjarCapture()"
  data-testid="booking-widget-entry"
>
  <h1 *ngIf="headingLevel === 1" class="nordic-blue-900-text font-heading-2" data-testid="booking-widget-title">
    {{ 'bookingSearch.title' | finLocalization }}
  </h1>
  <h2 *ngIf="headingLevel === 2" class="nordic-blue-900-text font-heading-2" data-testid="booking-widget-title">
    {{ 'bookingSearch.title' | finLocalization }}
  </h2>

  <ng-container *ngIf="activeTab$ | async as activeTab">
    <fcom-tabs
      class="ps-medium-t"
      [theme]="TabTheme.DEFAULT"
      [bottomBorder]="false"
      [whiteSpaceNowrap]="true"
      (clickedTab)="setActiveTab($event)"
      [prevButtonAriaLabel]="'carousel.previous' | finLocalization"
      [nextButtonAriaLabel]="'carousel.next' | finLocalization"
      data-testid="booking-widget-tabs"
    >
      <!-- flight -->
      <fcom-tab
        [title]="'bookingWidgetTabs.flight' | finLocalization"
        [attr.data-testid]="'booking-widget-tab-' + WidgetTab.FLIGHT"
        [active]="activeTab === WidgetTab.FLIGHT"
      >
        <fin-booking-widget-common
          *ngIf="activeTab === WidgetTab.FLIGHT"
          [defaultLocations$]="defaultLocations$"
          [layout]="layout"
          data-testid="booking-widget-flight"
          [identifier]="calendarIdentifier"
          [isFrontPage]="isFrontPage$ | async"
          [compactMode]="compactMode"
        ></fin-booking-widget-common>
      </fcom-tab>

      <!-- award -->
      <fcom-tab
        [title]="'bookingWidgetTabs.award' | finLocalization"
        [attr.data-testid]="'booking-widget-tab-' + WidgetTab.AWARD"
        [active]="activeTab === WidgetTab.AWARD"
      >
        <ng-container *ngIf="activeTab === WidgetTab.AWARD">
          <ng-container [ngSwitch]="loginStatus$ | async">
            <fin-login-card
              *ngSwitchCase="LoginStatus.NOT_LOGGED_IN"
              [benefitMode]="'text'"
              [benefitText]="'award.login.description'"
              [title]="'award.login.title'"
            ></fin-login-card>
            <fcom-loader
              *ngSwitchCase="LoginStatus.PENDING"
              [loaderType]="LoaderTheme.EMBEDDED"
              [title]="'loader.title'"
            ></fcom-loader>
            <ng-container *ngSwitchCase="LoginStatus.LOGGED_IN">
              <fin-booking-widget-common
                [defaultLocations$]="defaultLocations$"
                [layout]="layout"
                data-testid="booking-widget-award"
                [identifier]="calendarIdentifier"
                [compactMode]="compactMode"
              ></fin-booking-widget-common>
            </ng-container>
          </ng-container>
        </ng-container>
      </fcom-tab>

      <!-- flightPlusHotel -->
      <fcom-tab
        *ngIf="amTabEnabled$ | async"
        [title]="'bookingWidgetTabs.flightAndHotel' | finLocalization"
        [attr.data-testid]="'booking-widget-tab-' + WidgetTab.AM"
        [active]="activeTab === WidgetTab.AM"
      >
        <ng-container *ngIf="activeTab === WidgetTab.AM">
          <fin-am-booking-widget
            (setLocations)="onAmLocationChanged()"
            data-testid="booking-widget-am"
          ></fin-am-booking-widget>
        </ng-container>
      </fcom-tab>

      <!-- carTrawler -->
      <fcom-tab
        *ngIf="carTrawlerTabEnabled"
        [title]="'bookingWidgetTabs.carTrawler.tabTitle' | finLocalization"
        [attr.data-testid]="'booking-widget-tab-' + WidgetTab.CARTRAWLER"
        [active]="activeTab === WidgetTab.CARTRAWLER"
      >
        <fin-cartrawler-booking-widget *ngIf="activeTab === WidgetTab.CARTRAWLER"></fin-cartrawler-booking-widget>
      </fcom-tab>
    </fcom-tabs>
  </ng-container>
</div>
