<ng-container *ngIf="content$ | async as content">
  <div
    class="teaser-list grid-col-12 no-gutters"
    [ngClass]="{ 'no-row-gap': content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST) }"
  >
    @if (items$ | async; as items) {
      <cms-heading-tag
        *ngIf="content.teaserTitle && renderHeading(content.subjectTaxonomyTags)"
        class="col-1-12 ps-xsmall-b"
        [ngClass]="{ 'mr-large-b': content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST) }"
        [level]="headerLevel(content.subjectTaxonomyTags || []) ?? DEFAULT_HEADER_LEVEL"
        [extraClasses]="headerFontLevel(content.subjectTaxonomyTags || []) ?? 'font-heading-3'"
        >{{ content.teaserTitle }}</cms-heading-tag
      >

      @if (content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_LIST_TEASER_STYLE)) {
        <fin-teaser
          *ngFor="let teaserItem of items; trackBy: trackByUrl"
          #listItem
          [class]="content.gridClass || 'col-1-12 md-col-1-8'"
          [content]="teaserItem"
        ></fin-teaser>
      } @else if (content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST)) {
        <div class="col-1-12 flex flex--column row-gap-16">
          <fin-text-link-left
            *ngFor="let teaserItem of items; trackBy: trackByUrl"
            #listItem
            [teaserTitle$]="teaserItem.teaserTitle | finToObservable"
            [target$]="teaserItem | finToObservable"
            [useIcon]="false"
            [underline]="true"
          ></fin-text-link-left>
        </div>
      } @else if (content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_TEASER_LIST_THIRD)) {
        <fin-teaser-card
          *ngFor="let teaserItem of items; trackBy: trackByUrl"
          #listItem
          class="col-1-12 teaser-wrapper grid-third"
          [aspectRatio]="'16x9'"
          [content]="teaserItem"
          [alignLeft]="true"
        ></fin-teaser-card>
      } @else {
        <fin-teaser-card
          *ngFor="let teaserItem of items; trackBy: trackByUrl"
          #listItem
          class="col-1-12 teaser-wrapper"
          [aspectRatio]="'16x9'"
          [content]="teaserItem"
        ></fin-teaser-card>
      }
    }
    <div
      class="col-1-12 ms-small-y flex load-more"
      [ngClass]="{ 'ps-small-t': content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST) }"
      *ngIf="showLoadMore$ | async"
    >
      <fcom-button
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [text]="'actions.loadMore' | finLocalization"
        [href]="nextPageLink$ | async"
        [loading]="loading"
        (clickBtn)="loadNextItems($event)"
      ></fcom-button>
    </div>

    @if (content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST) && content.teaserText) {
      <div class="col-1-12 teaser-text flex pr-large-t" [innerHTML]="content.teaserText | finSafe: 'html'"></div>
    }
  </div>
</ng-container>
