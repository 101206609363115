<fcom-modal
  *finLet="consentApiStatus$ | async as consentApiStatus"
  [open]="modalOpen"
  [buttons]="ModalButtons.CUSTOM"
  [fitToContent]="true"
  [title]="
    consentApiStatus === ConsentApiStatus.NOT_LOADED || consentApiStatus === ConsentApiStatus.LOADED
      ? ('plus.memberMarketingPreferences.title' | finLocalization)
      : consentApiStatus === ConsentApiStatus.SAVED
        ? ('plus.memberMarketingPreferences.successTitle' | finLocalization)
        : ('plus.memberMarketingPreferences.errorTitle' | finLocalization)
  "
  [overlayClickable]="true"
  (close)="closeModal()"
>
  <ng-container [ngSwitch]="consentApiStatus">
    <form
      *ngSwitchCase="ConsentApiStatus.NOT_LOADED"
      #form="ngForm"
      (ngSubmit)="sendConsents()"
      class="marketing-preferences pr-large-x pr-medium-y text"
    >
      <fieldset>
        <legend class="align-left pr-medium-b">
          <div class="font-body-1">
            <p class="pr-small-b ps-xxsmall-t">
              {{ 'plus.memberMarketingPreferences.memberNumber' | finLocalization }}:
              <strong>{{ memberNumber$ | async }}</strong>
            </p>
            <p
              data-testid="cms-description"
              [innerHTML]="'plus.memberMarketingPreferences.description' | finLocalization | finSafe: 'html'"
            ></p>
          </div>
        </legend>

        <ng-container *ngFor="let consent of consents$ | async">
          <input
            #box
            [ngModel]="consent.consentStatus === 'accepted'"
            type="checkbox"
            [name]="consent.consentTextId"
            [id]="consent.consentTextId"
          />
          <label class="pr-xsmall-b" [for]="consent.consentTextId">
            <span class="checkbox" role="presentation">
              <fcom-icon
                *ngIf="box.checked"
                class="icon-medium"
                [dataTestId]="SvgLibraryIcon.CHECKBOX_SELECTED"
                [name]="SvgLibraryIcon.CHECKBOX_SELECTED"
              ></fcom-icon>
              <fcom-icon
                *ngIf="!box.checked"
                class="icon-medium"
                [dataTestId]="SvgLibraryIcon.CHECKBOX_UNSELECTED"
                [name]="SvgLibraryIcon.CHECKBOX_UNSELECTED"
              ></fcom-icon>
            </span>
            <span class="content" [innerHTML]="consent.text | finSafe: 'html'"></span>
          </label>
        </ng-container>
      </fieldset>
      <hr />
      <p
        class="pr-small-y align-left"
        [innerHTML]="'plus.memberMarketingPreferences.disclaimer' | finLocalization | finSafe: 'html'"
      ></p>
    </form>

    <p
      *ngSwitchCase="ConsentApiStatus.SAVED"
      class="pr-large-x pr-small-y"
      [innerHTML]="'plus.memberMarketingPreferences.success' | finLocalization | finSafe: 'html'"
    ></p>

    <p
      *ngSwitchCase="ConsentApiStatus.ERROR"
      class="pr-large-x pr-small-y"
      [innerHTML]="'plus.memberMarketingPreferences.error' | finLocalization | finSafe: 'html'"
    ></p>
  </ng-container>

  <div buttons>
    <ng-container [ngSwitch]="consentApiStatus">
      <ng-container *ngSwitchCase="ConsentApiStatus.NOT_LOADED">
        <fcom-button
          class="pr-xxsmall-l"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [text]="'plus.memberMarketingPreferences.saveButtonTitle' | finLocalization"
          [disabled]="!marketingPreferencesForm?.valid"
          data-testid="save-button"
          (clickBtn)="sendConsents()"
        ></fcom-button>
      </ng-container>
      <ng-container *ngSwitchCase="ConsentApiStatus.SAVED">
        <fcom-button
          *finLet="successButtonUrl$ | async as successButtonUrl"
          [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
          [text]="'plus.memberMarketingPreferences.successButtonTitle' | finLocalization"
          data-testid="success-button"
          (clickBtn)="onClick(successButtonUrl)"
        ></fcom-button>
      </ng-container>
      <ng-container *ngSwitchCase="ConsentApiStatus.ERROR">
        <fcom-button
          *finLet="errorButtonUrl$ | async as errorButtonUrl"
          [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
          [text]="'plus.memberMarketingPreferences.errorButtonTitle' | finLocalization"
          data-testid="error-button"
          (clickBtn)="onClick(errorButtonUrl)"
        ></fcom-button>
      </ng-container>
    </ng-container>
  </div>
</fcom-modal>
