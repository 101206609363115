import { Amount } from '@fcom/dapi';
import { Cabin, ControlData, FinnairCart, FinnairOrder, PaymentTransaction } from '@fcom/dapi/api/models';
import { BookingTripLocations } from '@fcom/common-booking';
import { LocationsMap } from '@fcom/core-api/interfaces';

import {
  ConsentData,
  ElementTypes,
  GA4Product,
  GA4PurchaseEvent,
  GtmEcommerceClickProduct,
  GtmEcommerceImpressionProduct,
  GtmEcommerceList,
  GtmEcommerceMeasurement,
  GtmEcommerceProduct,
  GtmEvent,
  GtmEventData,
  GtmFlightSelectionData,
  GtmPurchaseFlow,
  GtmTripType,
  PromotionItem,
  PromotionType,
  SeatMapData,
  SiteSearchEvents,
  SearchType,
  OfferListFetchParams,
  PreFlightSearchParams,
  GtmClickOutboundData,
  GTtmMulticityTicketTypeOptions,
  GtmMulticityFareFamilySelectionData,
  GtmMulticityOfferSelectionData,
} from '../../interfaces';

export abstract class GtmService {
  abstract pageView(path: string): void;

  abstract originalPageView(path: string): void;

  abstract internalPromotion(promotionItemArray: PromotionItem[], type: PromotionType): void;

  /**
   * GTM has a logic to find last valid value for specific object keys with same name.
   * This brings the need to 'flush' optional values with undefined, so that the gtm does not use
   * previous event's values for more recently pushed events.
   */
  abstract trackElement(
    elementName: string,
    context: string | undefined,
    elementType: ElementTypes,
    state: string | undefined,
    action: string | undefined,
    token?: string,
    trackElement?: SearchType
  ): void;

  abstract flightSelection(event: GtmEvent, data: GtmFlightSelectionData[]): void;

  abstract multicityFareFamilySelection(event: GtmEvent, data: GtmMulticityFareFamilySelectionData): void;

  abstract multicityOfferSelection(event: GtmEvent, data: GtmMulticityOfferSelectionData[]): void;

  abstract paxDetailsFlightReview(event: GtmEvent, data: GtmFlightSelectionData[]): void;

  abstract flowData(data: ControlData, purchaseFlow: GtmPurchaseFlow): void;

  abstract destinationPromotion(element: string, destCity: string, destCountry: string): void;

  abstract setConsents(consentData: ConsentData): void;

  abstract seatMapEvent(
    elementName: string,
    elementType: ElementTypes,
    state: string | undefined,
    action: string | undefined,
    seatMapData: SeatMapData
  ): void;

  /**
   * @TODO: When more ancillaries are implemented, we might want to rethink how they are implemented to
   * the gtm offer creation. For now seats are handled as their separate parameter
   * to the purchase function.
   * NOTE: orderData.seatSelections will be deprecated in the future. After this it will be
   * replaced with information inside orderData.services.
   * To prepare for this we are already sending the orderData.services to the dataLayer
   */
  abstract completePurchase(
    givenOrderData: FinnairOrder,
    type: GtmTripType,
    total: Amount,
    storeLocations: BookingTripLocations,
    totalPoints: string | undefined,
    purchaseFlow: GtmPurchaseFlow,
    discountCode?: string
  ): void;

  abstract completeManageBookingPurchase(
    order: FinnairOrder,
    paymentMethod: PaymentTransaction,
    purchaseFlow: GtmPurchaseFlow
  ): void;

  /**
   * @deprecated use ecommerceEventGA4 for GA4
   * Used for old Google analytics.
   * @param event
   * @param type
   * @param products
   * @param purchaseFlow
   * @param currencyCode
   */
  abstract ecommerceEvent(
    event: GtmEvent,
    type: GtmEcommerceMeasurement.ADD | GtmEcommerceMeasurement.REMOVE | GtmEcommerceMeasurement.IMPRESSIONS,
    products: GtmEcommerceProduct[] | GtmEcommerceImpressionProduct[],
    purchaseFlow: GtmPurchaseFlow,
    currencyCode?: string
  ): void;

  /**
   * Event for:
   * - adding products from cart/order
   * - removing products from cart/order
   * - viewing not purchased items cart|order
   * - initializing checkout (purchase)
   * @param event
   * @param products
   */
  abstract ecommerceEventGA4(
    event:
      | GtmEvent.ADD_TO_CART
      | GtmEvent.REMOVE_FROM_CART
      | GtmEvent.VIEW_CART
      | GtmEvent.BEGIN_CHECKOUT
      | GtmEvent.VIEW_ITEM_LIST
      | GtmEvent.SELECT_ITEM,
    products: GA4Product[]
  ): void;

  abstract enhancedEcommerceProductClickOrDetailView(
    event: GtmEvent,
    type: GtmEcommerceMeasurement,
    product: GtmEcommerceClickProduct,
    list: GtmEcommerceList,
    purchaseFlow: GtmPurchaseFlow,
    currencyCode?: string
  ): void;

  abstract ecommercePurchaseEventGa4(eventData: GA4PurchaseEvent): void;

  abstract pushEventToDataLayer(event: GtmEvent, eventData?: GtmEventData): void;

  abstract checkoutFlights(cartData: FinnairCart, purchaseFlow: GtmPurchaseFlow): void;

  abstract siteSearch(
    event: SiteSearchEvents,
    query: string,
    results: number,
    pageNumber: number,
    clickUrl?: string
  ): void;

  abstract preFlightSearch(params: PreFlightSearchParams): void;

  abstract flightSearch(params: OfferListFetchParams, locations: LocationsMap): void;

  abstract clickOutboundEvent(event: GtmClickOutboundData): void;

  abstract multicityTicketTypeOptions(cabin: Cabin, ticketTypeOptions: GTtmMulticityTicketTypeOptions): void;
}
