import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';

import { AttachmentError, AttachmentStatus, type Attachment } from '../../interfaces';

/**
 * Validates attachment extensions based on files used with the `fcom-file-uploader` component.
 * @example
 * Usage with form builder:
 * ```ts
 * this.reactiveForm = fb.group({
 *   [Enum.CONTROL_NAME]: ['', [attachmentExtensionValidator(['jpg', 'png'])]]
 * });
 * ```
 * @example
 * Validation error:
 * ```ts
 * {
 *   extension: {
 *     value: [{ file: 'file.pdf', status: AttachmentStatus.ERROR }]
 *   }
 * }
 * ```
 * @returns `null` or `extension` validation error with an array of files not matching the extensions given.
 */
export const attachmentExtensionValidator = (allowedExtensions: string[]): ValidatorFn => {
  const hasForbiddenAttachmentExtension = (extensions: string[], { file }: Attachment): boolean => {
    const extName = (file?.name || '').toLowerCase().split('.').pop();
    return !extensions.includes(extName);
  };

  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const attachmentsWithForbiddenExtensions: Attachment[] = control.value
      .filter(Boolean)
      .filter((attachment: Attachment): boolean => hasForbiddenAttachmentExtension(allowedExtensions, attachment));
    return attachmentsWithForbiddenExtensions.length
      ? {
          [AttachmentError.EXTENSION]: {
            value: attachmentsWithForbiddenExtensions.map((attachment: Attachment): Attachment => {
              attachment.status = AttachmentStatus.ERROR;
              return attachment;
            }),
          },
        }
      : null;
  };
};

/**
 * Validates attachment file sizes based on files used with the `fcom-file-uploader` component.
 * @example
 * Usage with form builder:
 * ```ts
 * this.reactiveForm = fb.group({
 *   [Enum.CONTROL_NAME]: [null, [attachmentFileSizeValidator(1024)]]
 * });
 * ```
 * @example
 * Validation error:
 * ```ts
 * {
 *   maxSize: {
 *     value: [{ file: 'file.pdf', status: AttachmentStatus.ERROR }]
 *   }
 * }
 * ```
 * @returns `null` or `maxSize` validation error with an array of files
 * not matching the max size in bytes given.
 */
export const attachmentFileSizeValidator = (maxSizeInBytes: number): ValidatorFn => {
  const exceedsMaxSizeInBytes = ({ file }: Attachment, size: number): boolean => file.size > size;
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const attachmentsWithInvalidFileSizes: Attachment[] = control.value
      .filter(Boolean)
      .filter(
        (attachment: Attachment): boolean => !attachment.file?.size || exceedsMaxSizeInBytes(attachment, maxSizeInBytes)
      );
    return attachmentsWithInvalidFileSizes.length
      ? {
          [AttachmentError.MAX_SIZE]: {
            value: attachmentsWithInvalidFileSizes.map((attachment: Attachment): Attachment => {
              attachment.status = AttachmentStatus.ERROR;
              return attachment;
            }),
          },
        }
      : null;
  };
};

/**
 * Validates attachment statuses based on files used with the `fcom-file-uploader` component.
 * @example
 * Validation error:
 * ```ts
 * {
 *   uploading: {
 *     value: [{ file: 'file.pdf', status: AttachmentStatus.UPLOADING }]
 *   }
 * }
 * ```
 * @returns `null` or :
 * - `uploading` validation error with an array of files not matching the extensions given.
 */
export const attachmentStatusValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const attachmentsStillUploading: Attachment[] = control.value
      .filter(Boolean)
      .filter((attachment: Attachment): boolean => attachment.status === AttachmentStatus.UPLOADING);
    const errors: ValidationErrors = {};
    if (attachmentsStillUploading.length) {
      errors[AttachmentError.UPLOADING] = { value: attachmentsStillUploading };
    }
    return Object.keys(errors).length ? errors : null;
  };
};
