import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { combineLatest, map, Observable, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';

import { ConfigService } from '@fcom/core/services';
import { activeTab, BookingWidgetAppState } from '@fcom/booking-widget/store';
import { WidgetTab } from '@fcom/booking-widget/interfaces';
import { TripType } from '@fcom/core/constants';

import { BookingAppState } from '../interfaces/booking';
import { globalBookingTripType } from '../store';

const DD_TRACE_HEADER_TAG_KEY = 'x-dd-flow-type';

enum FlowType {
  FLIGHT = 'flight',
  AWARD = 'award',
  MULTICITY = 'multicity',
}

@Injectable({
  providedIn: 'root',
})
export class DatadogHeadersInterceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigService,
    private store$: Store<BookingWidgetAppState>,
    private bookingStore$: Store<BookingAppState>
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.url.startsWith(this.configService.cfg.offersUrl) && !req.url.startsWith(this.configService.cfg.cartUrl)) {
      return next.handle(req);
    }

    return this.getFlowType().pipe(
      switchMap((flowType) => {
        const request: HttpRequest<unknown> = req.clone({
          headers: req.headers.set(DD_TRACE_HEADER_TAG_KEY, flowType),
        });
        return next.handle(request);
      })
    );
  }

  private getFlowType(): Observable<FlowType> {
    return combineLatest([this.bookingStore$.pipe(globalBookingTripType()), this.store$.pipe(activeTab())]).pipe(
      take(1),
      map(([tripType, widgetTab]) => {
        if (widgetTab === WidgetTab.AWARD) {
          return FlowType.AWARD;
        }

        if (tripType === TripType.MULTICITY) {
          return FlowType.MULTICITY;
        }

        return FlowType.FLIGHT;
      })
    );
  }
}
