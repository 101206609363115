import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, EMPTY, iif, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { capitalize } from '@fcom/core/utils';
import { CabinClassSelectionFareFamily } from '@fcom/booking/modules/ticket-selection/interfaces';
import { ButtonMode, ButtonSize, ButtonTheme, TagTheme, IconPosition, ResponsiveImage } from '@fcom/ui-components';
import { FinnairCabinClass } from '@fcom/dapi/api/models';
import { MediaQueryService } from '@fcom/common/services';
import { GtmService } from '@fcom/common/gtm';
import { ElementActions, ElementTypes } from '@fcom/common/interfaces';
import { ConfigService } from '@fcom/core/services';
import { TripType } from '@fcom/core/constants';

@Component({
  selector: 'fin-upsell-option',
  templateUrl: './upsell-option.component.html',
  styleUrls: ['./upsell-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsellOptionComponent implements OnInit {
  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly ButtonTheme = ButtonTheme;
  readonly FinnairCabinClass = FinnairCabinClass;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagTheme = TagTheme;

  @Input()
  fareFamily: CabinClassSelectionFareFamily | undefined;
  @Input()
  currencyCode$: Observable<string>;
  @Input()
  isShortHaul$: Observable<boolean>;
  @Input()
  isLongHaulLegAndAy$: Observable<boolean> = EMPTY;
  @Input()
  tripType$: Observable<TripType> = EMPTY;

  @Output()
  selectNextCabinClass: EventEmitter<CabinClassSelectionFareFamily> = new EventEmitter();

  @Output()
  openCabinClassModal: EventEmitter<string> = new EventEmitter();

  imageData$: Observable<ResponsiveImage>;
  isMobile$: Observable<boolean>;
  descriptionKey$: Observable<string>;
  hideCabinClassModalLink$: Observable<boolean>;
  aviosEnabled: boolean;
  showCompareBlock$: Observable<boolean>;
  enableBenefitChanges: boolean;

  constructor(
    private languageService: LanguageService,
    private httpClient: HttpClient,
    private mediaQueryService: MediaQueryService,
    private gtmService: GtmService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');

    this.enableBenefitChanges = this.configService.cfg.enableTicketTypeCardChanges;

    this.showCompareBlock$ = iif(
      () => !this.fareFamily,
      this.isLongHaulLegAndAy$.pipe(map((isLongHaulLegAndAy) => isLongHaulLegAndAy)),
      of(false)
    );

    const imageKey$ = combineLatest([this.isShortHaul$, this.isLongHaulLegAndAy$]).pipe(
      map(([isShortHaul, isLongHaulLegAndAy]) => {
        if (!this.fareFamily) {
          return !isShortHaul ? 'fragments.upsellGenericLongHaul' : undefined;
        }
        const cabinClass = capitalize(this.fareFamily.cabinClass);
        if (isShortHaul || isLongHaulLegAndAy || this.fareFamily.cabinClass === FinnairCabinClass.ECOPREMIUM) {
          const haul = isShortHaul ? 'ShortHaul' : 'LongHaul';

          return `fragments.upsell${cabinClass}${haul}`;
        }
        return `fragments.upsell${cabinClass}`;
      })
    );

    this.descriptionKey$ = combineLatest([this.isShortHaul$, this.isLongHaulLegAndAy$]).pipe(
      map(([isShortHaul, isLongHaulLegAndAy]) => {
        if (!this.fareFamily) {
          return isLongHaulLegAndAy ? 'upsellCard.description.genericLongHaul' : undefined;
        }
        if (this.fareFamily.cabinClass === FinnairCabinClass.FIRST) {
          return 'upsellCard.description.firstClassLongHaul';
        }
        if (isShortHaul || isLongHaulLegAndAy || this.fareFamily.cabinClass === FinnairCabinClass.ECOPREMIUM) {
          const haul = isShortHaul ? 'ShortHaul' : 'LongHaul';

          return `upsellCard.description.${this.fareFamily.cabinClass.toLowerCase()}${haul}`;
        }
        return `upsell.cabinClassDescription.${this.fareFamily.cabinClass.toLowerCase()}`;
      })
    );

    this.imageData$ = imageKey$.pipe(
      switchMap((imageKey) =>
        this.languageService.translate(imageKey).pipe(
          map((translation) => translation?.picture?.jsonUrl),
          filter(Boolean),
          switchMap((jsonUrl: string) => this.httpClient.get<ResponsiveImage>(jsonUrl))
        )
      )
    );

    this.hideCabinClassModalLink$ = combineLatest([this.isShortHaul$, this.isLongHaulLegAndAy$]).pipe(
      map(([isShortHaul, isLongHaulLegAndAy]) => isShortHaul || !isLongHaulLegAndAy)
    );

    const isAward = !!this.fareFamily?.points;
    this.aviosEnabled = this.configService.cfg.enableLoyaltyAvios.bookingFlowNotifications && !isAward;
  }

  emitOpenCabinClassModal(event: Event, cabinClass: string): void {
    this.openCabinClassModal.emit(cabinClass);
    this.gtmService.trackElement(
      'upsell-card-modal-link',
      'booking-flow_flight-selection',
      ElementTypes.BUTTON,
      undefined,
      ElementActions.CLICK
    );
    event.preventDefault();
  }
}
