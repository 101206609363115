import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerIntl } from '@angular/material/datepicker';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { LanguageService } from '@fcom/ui-translate';
import { LoaderType } from '@fcom/common/';
import { LocalDate } from '@fcom/core/utils';

import { DatePickerLocalizationService } from '../../services/datepicker-localization.service';
import { CustomDateAdapter, dayMonthYearFormat } from '../../services/datepicker-adapter';

export enum DatepickerTheme {
  DEFAULT = 'default',
  WEB_2 = 'web-2',
}

@Component({
  selector: 'fin-datepicker',
  styleUrls: ['./datepicker.component.scss'],
  templateUrl: 'datepicker.component.html',
  providers: [
    { provide: MatDatepickerIntl, useClass: DatePickerLocalizationService },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: dayMonthYearFormat },
  ],
})
export class DatepickerComponent implements OnInit {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  displayMargin = true;

  @Input()
  translationPrefix: string;

  @Input()
  placeHolder: string = null;

  @Input()
  minDate: LocalDate = LocalDate.forDateObj(new Date(0));

  @Input()
  maxDate: LocalDate = LocalDate.now();

  @Input()
  id: string;

  @Input()
  icon: SvgLibraryIcon = SvgLibraryIcon.BOOK_FLIGHT;

  @Input()
  theme = DatepickerTheme.DEFAULT;

  public readonly LoaderType = LoaderType;
  public readonly DatepickerTheme = DatepickerTheme;
  public readonly SvgLibraryIcon = SvgLibraryIcon;

  optionsAmount = 0;
  selectedDay: number;
  selectedMonth: number;
  selectedYear: number;
  isRequired = false;
  idOrControlName: string;

  constructor(
    private languageService: LanguageService,
    private adapter: DateAdapter<CustomDateAdapter>
  ) {}

  ngOnInit(): void {
    this.setLocale();

    this.isRequired = this.required;
    this.idOrControlName = this.id || this.controlName;
  }

  setLocale(): void {
    const lang = this.languageService.localeValue.replace('_', '-');

    this.adapter.setLocale(lang);
  }

  signalSelection(eventValue: MatDatepickerInputEvent<any>): void {
    if (eventValue.value && eventValue.value instanceof LocalDate) {
      this.selectedDay = eventValue.value.localDay;
      this.selectedMonth = eventValue.value.localMonth;
      this.selectedYear = eventValue.value.localYear;
    }
  }

  get required(): boolean {
    return this.ctrlField.errors?.required;
  }

  get ctrlField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }
}
