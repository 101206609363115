import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { ModalButtons, ButtonTheme } from '@fcom/ui-components';
import { ProfileType, ProfileTier, LoginMemberStatus } from '@fcom/core-api/login';
import { AppState } from '@fcom/core';
import { loginToken, profileType, profile } from '@fcom/core/selectors';
import { unsubscribe } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';

import { Consent, ConsentGroup, ConsentStatus } from '../../interfaces';
import { ConsentService, ConsentToken, ConsentTokenType } from '../services/consent.service';

export enum ConsentApiStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADED = 'LOADED',
  SAVED = 'SAVED',
  ERROR = 'ERROR',
}

const consentPreferencesToken = 'consent_preferences_token';

@Component({
  selector: 'fin-marketing-preferences',
  styleUrls: ['marketing-preferences.component.scss'],
  templateUrl: 'marketing-preferences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingPreferencesComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: false })
  marketingPreferencesForm!: NgForm;

  consents$: Observable<Consent[]>;
  memberNumber$: Observable<string>;
  modalOpen = false;
  consentApiStatus$ = new BehaviorSubject<ConsentApiStatus>(ConsentApiStatus.NOT_LOADED);

  successButtonUrl$: Observable<string>;
  errorButtonUrl$: Observable<string>;

  private consentGroup$: Observable<ConsentGroup>;
  private subscription: Subscription = new Subscription();
  private token$: Observable<ConsentToken>;

  readonly CustomIcon = CustomIcon;
  readonly IconLibrary = IconLibrary;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly ConsentApiStatus = ConsentApiStatus;
  readonly ButtonTheme = ButtonTheme;
  readonly ModalButtons = ModalButtons;

  token: string;

  constructor(
    private marketingConsentService: ConsentService,
    private store$: Store<AppState>,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const tokenFromUrl = this.route.snapshot.queryParamMap.get(consentPreferencesToken);

    let accountType$: Observable<ProfileType>;

    if (tokenFromUrl) {
      this.token$ = of({ token: tokenFromUrl, type: ConsentTokenType.CAT });
      accountType$ = of(ProfileType.FPLUS);
      this.modalOpen = true;
    } else {
      this.token$ = this.store$.pipe(
        loginToken(),
        filter((v) => !!v),
        take(1),
        map((v) => ({
          token: v,
        }))
      );
      accountType$ = this.store$.pipe(
        profileType(),
        filter((v) => !!v),
        take(1)
      );
    }

    this.consentGroup$ = combineLatest([this.token$, accountType$]).pipe(
      switchMap(([authHeaderValue, type]) => this.marketingConsentService.getMarketingConsents(authHeaderValue, type)),
      filter((v) => !!v),
      finShare(),
      catchError(() => {
        this.consentApiStatus$.next(ConsentApiStatus.ERROR);
        return EMPTY;
      })
    );

    this.memberNumber$ = this.consentGroup$.pipe(map((group) => group.fplusMember));

    this.consents$ = this.consentGroup$.pipe(map((group) => group.consents));

    this.subscription.add(
      this.store$.pipe(profile()).subscribe((currentProfile) => {
        if (currentProfile.tier === ProfileTier.ACCOUNT && currentProfile.memberStatus === LoginMemberStatus.PENDING) {
          this.sendConsents();
        } else {
          this.checkReconsent();
        }
      })
    );

    this.successButtonUrl$ = this.languageService
      .translate('plus.memberMarketingPreferences.successButtonLink.url')
      .pipe(
        distinctUntilChanged(),
        filter((v) => !!v)
      );

    this.errorButtonUrl$ = this.languageService.translate('plus.memberMarketingPreferences.errorButtonLink.url').pipe(
      distinctUntilChanged(),
      filter((v) => !!v)
    );
  }

  checkReconsent(): void {
    this.subscription.add(
      this.consentGroup$
        .pipe(
          filter((group) => group.consents.filter((a) => a.reConsentRequired === true).length > 0),
          take(1)
        )
        .subscribe(() => {
          this.modalOpen = true;
        })
    );
  }

  sendConsents(): void {
    const formData = this.marketingPreferencesForm;

    this.subscription.add(
      combineLatest([this.token$, this.consents$])
        .pipe(
          switchMap(([token, consents]: [ConsentToken, Consent[]]) => {
            return this.marketingConsentService.sendConsents(
              token,
              consents.map(
                (c) =>
                  ({
                    ...c,
                    consentStatus: formData.value[c.consentTextId] ? ConsentStatus.ACCEPTED : ConsentStatus.DECLINED,
                  }) as Consent
              )
            );
          })
        )
        .subscribe(() => {
          this.consentApiStatus$.next(ConsentApiStatus.SAVED);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  closeModal(): void {
    this.modalOpen = false;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [consentPreferencesToken]: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  onClick(url: string): void {
    this.modalOpen = false;
    this.changeDetectorRef.detectChanges();
    this.router.navigateByUrl(url);
  }
}
