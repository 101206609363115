<ng-template
  #priceLine
  let-label="label"
  let-value="value"
  let-originalValue="originalValue"
  let-labelContent="labelContent"
  let-bold="bold"
  let-borderBottom="borderBottom"
>
  <tr class="ps-xxsmall-b" [ngClass]="{ 'border-bottom': borderBottom }">
    <th
      scope="row"
      class="align-left ps-xxsmall-b"
      [ngClass]="{ 'light-type': !bold, 'medium-type ms-xsmall-t': bold }"
    >
      <ng-container *ngIf="label; else labelContent">{{ label }}</ng-container>
    </th>
    <td class="align-right ps-xxsmall-b" [ngClass]="{ 'medium-type ms-xsmall-t': bold }">
      <span class="nordic-blue-900-text strike-through pr-xxsmall-r" *ngIf="originalValue">{{ originalValue }}</span
      >{{ value }}
    </td>
  </tr>
</ng-template>

<ng-template #titleLine let-label="label" let-labelContent="labelContent" let-bold="bold">
  <tr class="ps-xxsmall-b">
    <th
      scope="row"
      class="align-left ps-xxsmall-y"
      [ngClass]="{ 'light-type': !bold, 'medium-type ms-xsmall-t': bold }"
    >
      <ng-container *ngIf="label; else labelContent">{{ label }}</ng-container>
    </th>
  </tr>
</ng-template>

<ng-template #expandedLine let-borderBottom="borderBottom">
  <tr class="ps-xxsmall-b" [class.border-bottom]="borderBottom">
    <ng-content></ng-content>
  </tr>
</ng-template>

<fin-expand
  *ngIf="prices$ | async as prices"
  class="flex--basis-full block pr-small-x"
  [key]="'price-breakdown-expand'"
  [id]="'price-breakdown-expand'"
  [initiallyOpen]="expandOpen"
  [class.ps-small-b]="(expand.isOpen$ | async) === false"
  #expand
>
  <div class="trigger flex">
    <div class="flex-1">
      <h3 class="font-heading-4">
        <fcom-icon
          class="flex flex-1 flex--nogrow flex--self-start icon-large nordic-blue-900-text"
          [name]="SvgLibraryIcon.CREDIT_OR_DEBIT_CARD_BLOCK"
        ></fcom-icon>
        <span class="flex-1 flex--self-center ps-xsmall-l medium-type">
          {{ 'purchase.priceBreakdown.title' | finLocalization }}
        </span>
      </h3>
    </div>
    <fcom-icon
      class="flex-1 flex--nogrow rotate flex--self-start icon-medium nordic-blue-900-text"
      [class.rotate-active]="expand.isOpen$ | async"
      [name]="SvgLibraryIcon.CHEVRON_DOWN"
    ></fcom-icon>
  </div>
  <div class="font-body-1 ps-xsmall-t content">
    <ng-content select="fcom-notification"></ng-content>
    <table class="fill summary-table">
      <caption class="is-vishidden">
        {{
          'purchase.priceBreakdown.title' | finLocalization
        }}
      </caption>
      <thead>
        <tr>
          <th class="flex medium-type ps-xxsmall-b" scope="col" colspan="2">
            {{ 'purchase.priceBreakdown.flights' | finLocalization }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngIf="showBaseFare"
          [ngTemplateOutlet]="priceLine"
          [ngTemplateOutletContext]="{
            label:
              ((passengers$ | async).length === 1 ? 'tripCard.passenger' : 'tripCard.passengers')
              | finLocalization: { passengerAmount: (passengers$ | async).length },
            value:
              showPoints && prices?.flight?.total.totalPoints
                ? ('award.points'
                  | finLocalization: { pointsAmount: +prices?.flight?.total?.totalPoints.amount | finPoints })
                : (prices?.flight?.total.baseFare | finPrice),
            bold: false,
          }"
        >
        </ng-container>
        <ng-template #labelContent>
          <button
            data-testid="price-breakdown-total-tax"
            *ngIf="showTotalTax"
            (click)="openTaxModal($event, prices?.total.total.totalTax)"
            class="taxes-link underline nordic-blue-900-text"
          >
            {{ 'purchase.review.taxTotal' | finLocalization }}
          </button>
        </ng-template>
        <ng-container
          *ngIf="showTotalTax"
          [ngTemplateOutlet]="priceLine"
          [ngTemplateOutletContext]="{
            value:
              prices?.total.total.totalTax || { amount: '0', currencyCode: prices?.total.total.baseFare.currencyCode }
              | finPrice,
            labelContent: prices?.total.total.totalTax ? labelContent : null,
            label: prices?.total.total.totalTax ? null : ('purchase.review.taxTotal' | finLocalization),
            borderBottom: prices.services.totalPerCategory?.length,
            bold: true,
          }"
        >
        </ng-container>

        <ng-container
          *ngIf="prices.services.totalPerCategory?.length"
          [ngTemplateOutlet]="titleLine"
          [ngTemplateOutletContext]="{
            label: 'purchase.priceBreakdown.travelExtras' | finLocalization,
            bold: true,
          }"
        >
        </ng-container>
        <ng-container *ngIf="summary$ | async as summary">
          <ng-container *ngFor="let sum of summary">
            <ng-container
              *ngIf="sum.count"
              [ngTemplateOutlet]="priceLine"
              [ngTemplateOutletContext]="{
                label:
                  sum.key
                  | finLocalization
                    : {
                        seatCount: sum.count,
                        mealCount: sum.count,
                        baggageCount: sum.count,
                        loungeCount: sum.count,
                        travelComfortCount: sum.count,
                        wifiCount: sum.count,
                        coverCount: sum.count,
                        petCount: sum.count,
                        sportCount: sum.count,
                        specialCount: sum.count,
                        firearmCount: sum.count,
                        medicCount: sum.count,
                        specialNeedCount: sum.count,
                        childCount: sum.count,
                        amount: sum.count,
                      },
                value: sum.total | finPrice,
                originalValue: sum.originalTotal | finPrice,
                bold: false,
              }"
            >
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
      <tfoot></tfoot>
    </table>

    <div class="border-top ps-small flex flex--center">
      <button
        *ngIf="showTotalTax"
        (click)="openSummaryModal($event, prices)"
        class="detailed-breakdown underline nordic-blue-900-text bold-type"
      >
        {{ 'purchase.priceBreakdown.detailedBreakdown' | finLocalization }}
      </button>
    </div>
  </div>
</fin-expand>

<fcom-modal
  [(open)]="taxModalOpen"
  [title]="'purchase.review.taxTotal' | finLocalization"
  [buttons]="ModalButtons.CLOSE"
  (close)="closeTaxModal()"
>
  <div class="pr-large-x">
    <cms-unstyled-fragment
      *ngIf="'fragments.purchaseTaxesAndRefundRenewed.url' | finLocalization"
      class="block font-body-1 unstyled-fragment-line-height pr-xxsmall-y"
      [contentUrl$]="'fragments.purchaseTaxesAndRefundRenewed.url' | finLocalization | finToObservable"
    >
    </cms-unstyled-fragment>

    <table class="fill dictionary-table tax-table font-body-1" *ngIf="totalTaxes$ | async as totalTaxes">
      <caption class="is-vishidden">
        {{
          'purchase.review.taxTotal' | finLocalization
        }}
      </caption>
      <thead>
        <tr>
          <th class="bold-type ms-medium-y" scope="col" colspan="2">
            {{ 'purchase.priceBreakdown.perBooking' | finLocalization }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tax of totalTaxes" class="flex flex--space-between">
          <th scope="row">
            <ng-container *ngIf="tax.code !== ENVIRONMENTAL_SURCHARGE; else environmentalSurcharge">
              {{ 'TAX_CODES.' + tax.code.slice(0, 2) | finLocalization | finEmpty: tax.code.slice(0, 2) }}
            </ng-container>
            <ng-template #environmentalSurcharge>
              {{ 'TAX_CODES.' + ENVIRONMENTAL_SURCHARGE | finLocalization }}
            </ng-template>
          </th>
          <td>{{ tax | finPrice }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="flex flex--space-between border-top medium-type">
          <th scope="row">{{ 'price.total' | finLocalization }}</th>
          <td>{{ taxModalData?.totalTaxes | finPrice }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</fcom-modal>

<fcom-modal
  [(open)]="summaryModalOpen"
  [title]="'purchase.priceBreakdown.detailedBreakdownPassenger' | finLocalization"
  [buttons]="ModalButtons.DONE"
  (close)="closeSummaryModal()"
>
  <ng-container *ngFor="let breakdown of breakdowns$ | finObsToArray">
    <div class="pr-xsmall-t pr-large-l pr-xxlarge-r">
      <div class="trigger flex" *ngIf="breakdown | async as passenger">
        <div class="flex-1">
          <h3 class="font-heading-5 nordic-blue-50-bg">
            <span class="flex-1 flex--self-center ps-xsmall-l medium-type" data-hj-suppress>
              {{ passenger?.title ? ('passenger.title.' + passenger?.title | lowercase | finLocalization) : '' }}
              {{ passenger?.firstName }} {{ passenger?.lastName }} ({{
                'passenger.' + asPaxTypeKey(passenger.passengerTypeCode).toLowerCase() | finLocalization | titlecase
              }})
            </span>
          </h3>
        </div>
      </div>
      <div class="font-body-1 ps-xsmall-t content" *ngIf="breakdown | async as pax">
        <table class="fill price-breakdown-per-passenger">
          <caption class="is-vishidden">
            {{
              'purchase.priceBreakdown.detailedBreakdownPassenger' | finLocalization
            }}
          </caption>

          <thead>
            <tr>
              <th class="flex medium-type ps-xxsmall-b" scope="col" colspan="2">
                {{ 'purchase.priceBreakdown.flights' | finLocalization }}
              </th>
            </tr>
          </thead>

          <tbody>
            <ng-container
              *ngIf="showBaseFare"
              [ngTemplateOutlet]="priceLine"
              [ngTemplateOutletContext]="{
                label: 'purchase.priceBreakdown.flights' | finLocalization,
                originalValue: pax.prices?.originalBaseFare ? (pax.prices?.originalBaseFare | finPrice) : undefined,
                value:
                  showPoints && pax.prices?.totalPoints
                    ? ('award.points' | finLocalization: { pointsAmount: +pax.prices?.totalPoints.amount | finPoints })
                    : (pax.prices?.baseFare | finPrice),
                bold: false,
              }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="expandedLine"
              [ngTemplateOutletContext]="{ borderBottom: pax.services.length }"
            >
              <ng-container>
                <tr>
                  <td colspan="3">
                    <fin-expand
                      class="price-breakdown-modal"
                      [key]="'price-breakdown-expand-' + (breakdown | async)?.id"
                      [id]="'price-breakdown-expand-' + (breakdown | async)?.id"
                      #expand
                    >
                      <div class="trigger flex">
                        <div class="flex-1">
                          <span class="underline medium-type">
                            {{ 'purchase.review.taxTotal' | finLocalization }}
                          </span>
                        </div>
                        <div>
                          <span class="medium-type">{{
                            pax.prices?.totalTax || { amount: '0', currencyCode: pax.prices?.baseFare.currencyCode }
                              | finPrice
                          }}</span>
                          <span class="ps-xsmall-l">
                            <fcom-icon
                              class="flex-1 flex--nogrow rotate flex--self-start icon-medium nordic-blue-900-text"
                              [class.rotate-active]="expand.isOpen$ | async"
                              [name]="SvgLibraryIcon.CHEVRON_DOWN"
                            ></fcom-icon>
                          </span>
                        </div>
                      </div>
                      <div class="font-body-1 content">
                        <ul class="list-disc-style">
                          <li *ngFor="let tax of pax.prices.taxes">
                            <div class="flex flex--space-between">
                              <span>
                                {{
                                  'TAX_CODES.' + tax.code.slice(0, 2) | finLocalization | finEmpty: tax.code.slice(0, 2)
                                }}
                              </span>
                              <span>
                                {{ tax | finPrice }}
                              </span>
                            </div>
                          </li>
                          <li *ngIf="+pax.prices.environmentalSurcharge?.amount > 0">
                            <div class="flex flex--space-between">
                              <span>
                                {{ 'TAX_CODES.' + ENVIRONMENTAL_SURCHARGE | finLocalization }}
                              </span>
                              <span>
                                {{ pax.prices.environmentalSurcharge | finPrice }}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </fin-expand>
                  </td>
                </tr>
              </ng-container>
            </ng-container>

            <ng-container *ngFor="let fee of pax.prices?.fees">
              <ng-container
                [ngTemplateOutlet]="priceLine"
                [ngTemplateOutletContext]="{
                  label: 'purchase.review.' + fee.code | finLocalization,
                  value: fee | finPrice,
                  bold: false,
                }"
              >
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="pax.services.length"
              [ngTemplateOutlet]="titleLine"
              [ngTemplateOutletContext]="{
                label: 'purchase.priceBreakdown.travelExtras' | finLocalization,
                bold: true,
              }"
            >
            </ng-container>
            <ng-container *ngFor="let service of pax.services">
              <ng-container
                *ngIf="service.count"
                [ngTemplateOutlet]="priceLine"
                [ngTemplateOutletContext]="{
                  label:
                    service.key
                    | finLocalization
                      : {
                          seatCount: service.count,
                          mealCount: service.count,
                          baggageCount: service.count,
                          loungeCount: service.count,
                          travelComfortCount: service.count,
                          wifiCount: service.count,
                          coverCount: service.count,
                          petCount: service.count,
                          sportCount: service.count,
                          specialCount: service.count,
                          firearmCount: service.count,
                          medicCount: service.count,
                          specialNeedCount: service.count,
                          childCount: service.count,
                          amount: service.count,
                        },
                  value: service.total | finPrice,
                  originalValue: service.originalTotal | finPrice,
                  bold: false,
                }"
              >
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot class="border-top">
            <ng-container
              [ngTemplateOutlet]="priceLine"
              [ngTemplateOutletContext]="{
                label: ('purchase.priceBreakdown.totalPrice' | finLocalization),
                value:
                  showPoints && pax.prices?.totalPoints
                    ? ('award.points'
                        | finLocalization: { pointsAmount: +pax.prices?.totalPoints.amount | finPoints }) +
                      '&nbsp;+&nbsp;' +
                      (pax.total | finPrice)
                    : (pax.total | finPrice),
                bold: true,
              }"
            >
            </ng-container>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-container>
</fcom-modal>
