import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { TagTheme } from '@fcom/ui-components/components/tag';
import { ElementTypes, GaContext } from '@fcom/common/interfaces';
import { NotificationTheme } from '@fcom/ui-components';
import { FareFamily } from '@fcom/dapi/api/models/fare-family';
import {
  ClassOptions,
  HeaderItem,
  RowDataItem,
  TableComponent,
} from '@fcom/ui-components/components/table/table.component';
import { FinnairCabinClass, TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { unique, unsubscribe } from '@fcom/core/utils';
import { LocalizationPipe } from '@fcom/ui-translate';
import { ScrollHandleContainerComponent } from '@fcom/common/components/scroll-handle-container/scroll-handle-container.component';
import { effectiveCabinClass } from '@fcom/booking/modules/ticket-selection/utils/offer.utils';
import { ConfigService, TripType } from '@fcom/core/index';

import { DimensionsAndDetails, ExtendedFareFamily } from '../../../interfaces';
import { isByBusOnly } from '../../../utils/common-booking.utils';

enum DimensionsValues {
  SMALL = 'booking.baggageDimensions.smallBag',
  MEDIUM = 'booking.baggageDimensions.mediumBag',
  LARGE = 'booking.baggageDimensions.largeBag',
}

interface TranslatedBenefitRowWithOptions extends TranslatedBenefitRow {
  options?: {
    class?: ClassOptions;
  };
}

interface Benefit extends TranslatedBenefitRow {
  children?: TranslatedBenefitRowWithOptions[];
}

enum CheckedBaggage {
  WEIGHT_PLURAL = 'checkedBaggageWithAmountAndWeightPlural',
  WEIGHT_SINGULAR = 'checkedBaggageWithAmountAndWeightSingular',
  WITH_WEIGHT = 'checkedBaggageWithWeight',
}

@Component({
  selector: 'fin-fare-family-details-v2',
  templateUrl: './fare-family-details-v2.component.html',
  styleUrls: ['./fare-family-details-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FareFamilyDetailsV2Component implements AfterViewInit, OnDestroy, OnInit {
  readonly ElementTypes = ElementTypes;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagTheme = TagTheme;

  readonly TRACKING_CONTEXT = GaContext.FLIGHT_SELECTION;

  @Input()
  dimensionsAndDetails$: Observable<DimensionsAndDetails>;

  @Input()
  showKoreaNotification: boolean;

  @Input()
  splitByClasses = true;

  @Input()
  tripType$: Observable<TripType> = EMPTY;

  @ViewChild('itemTemplate', { read: TemplateRef }) itemTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { read: TemplateRef }) headerTemplate: TemplateRef<any>;
  @ViewChild('tableComponent', { static: false }) tableComponentElementRef!: TableComponent<TranslatedBenefitRow>;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ScrollHandleContainerComponent;

  fareFamiliesBenefits: FareFamily[];
  headers: HeaderItem[];
  rowData: RowDataItem<TranslatedBenefitRow>[];
  headersList: QueryList<ElementRef>;

  subscription = new Subscription();
  activeCabinClass$: BehaviorSubject<string> = new BehaviorSubject<string>(FinnairCabinClass.ECONOMY);
  availableClasses$: Observable<string[]>;
  hasBusLeg$: Observable<boolean>;
  enableBenefitChanges: boolean;

  constructor(
    private localization: LocalizationPipe,
    private cd: ChangeDetectorRef,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    const extendedFareFamilies$ = this.dimensionsAndDetails$.pipe(
      filter(Boolean),
      map(({ extendedFareFamilies }: DimensionsAndDetails) => extendedFareFamilies)
    );

    this.availableClasses$ = extendedFareFamilies$.pipe(
      map((extendedFareFamilies) =>
        this.splitByClasses
          ? extendedFareFamilies?.reduce((acc, item) => {
              const cabinClass = effectiveCabinClass(item);

              if (!acc.includes(cabinClass)) {
                acc.push(cabinClass);
              }
              return acc;
            }, [] as string[])
          : []
      )
    );

    this.hasBusLeg$ = this.dimensionsAndDetails$?.pipe(
      filter(Boolean),
      map(({ itineraries }) =>
        itineraries?.some((itineraries) => {
          return itineraries.some((itinerary) => isByBusOnly(itinerary));
        })
      )
    );

    this.subscription.add(
      this.dimensionsAndDetails$
        ?.pipe(
          filter(Boolean),
          tap(({ cabinClass }) => {
            this.activeCabinClass$.next(cabinClass);
          })
        )
        .subscribe()
    );

    this.subscription.add(
      combineLatest([extendedFareFamilies$, this.activeCabinClass$])
        .pipe(
          map(([fareFamilies, cabinClass]) => this.filterByCabinClass(fareFamilies, cabinClass)),
          map((fareFamilies: ExtendedFareFamily[]) => this.getBenefitsTableData(fareFamilies))
        )
        .subscribe(([headers, rowData]) => {
          this.headers = headers;
          this.rowData = rowData;

          if (this.scrollContainer) {
            this.scrollContainer.updateScrollHandles();
          }
        })
    );

    this.headersList = this.tableComponentElementRef?.tableHeaders;

    this.enableBenefitChanges = this.configService.cfg.enableTicketTypeCardChanges;
  }

  ngAfterViewInit(): void {
    this.headersList = this.tableComponentElementRef?.tableHeaders;
    this.cd.detectChanges();
  }

  selectCabinClass(cabinClass: string): void {
    this.activeCabinClass$.next(cabinClass);
  }

  private getBenefitsTableData(fareFamilies: ExtendedFareFamily[] = []): [HeaderItem[], RowDataItem<Benefit>[]] {
    const benefits = fareFamilies.map((item) => item.benefits);

    const headers = benefits.map((fareFamily, index) => {
      return {
        key: fareFamily.fareFamilyCode,
        value: fareFamily.brandName,
        options: {
          class: {
            'border-right': true,
            'border-left': index === 0,
            'rounded-top-left-large': index === 0,
            'rounded-top-right-large': benefits.length === index + 1,
            'border-top': true,
          },
          isMarked: false,
        },
      };
    });

    const translatedBenefitRows = this.getSortedTranslatedBenefits(benefits);

    const rowData = translatedBenefitRows.map((benefit, benefitIndex) => {
      const item = {} as RowDataItem<TranslatedBenefitRow>;
      benefits.forEach((fareFamily, index) => {
        const isLastRow = benefitIndex + 1 === benefits[0]?.translatedBenefitRows.length;

        const classOption: ClassOptions = {
          'border-right': true,
          'border-left': index === 0,
          'rounded-bottom-left-large': isLastRow && index === 0,
          'rounded-bottom-right-large': isLastRow && index + 1 === benefits.length,
        };

        let fareFamilyBenefit = fareFamily?.translatedBenefitRows.find(
          (b) => b.key === benefit.key || b.key.includes(benefit.key) || benefit.key.includes(b.key)
        );

        if (!fareFamilyBenefit && benefit.key === CheckedBaggage.WITH_WEIGHT) {
          fareFamilyBenefit = fareFamily?.translatedBenefitRows.find((b) =>
            [CheckedBaggage.WEIGHT_PLURAL, CheckedBaggage.WEIGHT_SINGULAR].includes(b.key as CheckedBaggage)
          );
        }

        if (
          !fareFamilyBenefit &&
          (benefit.key === CheckedBaggage.WEIGHT_PLURAL || benefit.key === CheckedBaggage.WEIGHT_SINGULAR)
        ) {
          fareFamilyBenefit = fareFamily?.translatedBenefitRows.find((b) =>
            [CheckedBaggage.WITH_WEIGHT].includes(b.key as CheckedBaggage)
          );
        }

        if (fareFamilyBenefit) {
          if (fareFamilyBenefit.key === 'cabinBaggageWithWeight') {
            const dimensionValuesMap = {
              smallBag: DimensionsValues.SMALL,
              cabinBagWithAmount: DimensionsValues.MEDIUM,
            };

            fareFamilyBenefit.children = (fareFamilyBenefit.children as TranslatedBenefitRowWithOptions[]).map(
              (child) => {
                child.value = this.localization.transform(dimensionValuesMap[child.key] || '') || child.value;
                return child;
              }
            );
          }

          if (fareFamilyBenefit.key.startsWith('checkedBaggage')) {
            if (!fareFamilyBenefit.label) {
              fareFamilyBenefit.label = benefit.label;
            }

            fareFamilyBenefit.value = fareFamilyBenefit.isPositive
              ? this.localization.transform(DimensionsValues.LARGE)
              : fareFamilyBenefit.value;
          }
        }

        item[fareFamily.fareFamilyCode as string] = {
          data: fareFamilyBenefit ? fareFamilyBenefit : { value: null, key: null },
          options: {
            class: { ...classOption },
          },
        };
      });

      return item;
    });

    return [headers, rowData];
  }

  private getSortedTranslatedBenefits(benefits: FareFamily[]): TranslatedBenefitRow[] {
    const translatedBenefitRows = this.getFullListOfBenefits(benefits);
    const orderedBenefits = this.getOrderForBenefits(benefits, translatedBenefitRows);

    return [...translatedBenefitRows].sort((a, b) => orderedBenefits.indexOf(a.key) - orderedBenefits.indexOf(b.key));
  }

  /**
   * Get list of benefits correctly ordered
   *
   * @param benefits
   * @param translatedBenefitRows
   * @private
   */
  private getOrderForBenefits(benefits: FareFamily[], translatedBenefitRows: TranslatedBenefitRow[]): string[] {
    const fareFamily = benefits[0];
    const fareFamilyKeys = translatedBenefitRows.map((b) => b.key);
    return fareFamily?.highlightedBenefitKeys
      ? [...fareFamily.highlightedBenefitKeys, ...fareFamilyKeys].filter(unique)
      : fareFamilyKeys;
  }

  /**
   * Get the translatedBenefitsRows with the maximum amount of translated keys.
   *
   * @param benefits
   * @private
   */
  private getFullListOfBenefits(benefits: FareFamily[]): TranslatedBenefitRow[] {
    return (
      benefits.reduce(
        (acc, curr) => {
          const rowCount = curr.translatedBenefitRows.length;

          if (rowCount > acc.count) {
            return { item: curr, count: rowCount };
          }

          return acc;
        },
        { item: null, count: 0 }
      ).item?.translatedBenefitRows || []
    );
  }

  private filterByCabinClass(fareFamilies, activeCabinClass): ExtendedFareFamily[] {
    return this.splitByClasses
      ? fareFamilies.filter((fareFamily) => effectiveCabinClass(fareFamily) === activeCabinClass)
      : fareFamilies;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }
}
