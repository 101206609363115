import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { EMPTY, Observable } from 'rxjs';

import { FinnairBoundFareFamily, TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { stopPropagation } from '@fcom/core/utils';
import { NotificationLayout, NotificationTheme } from '@fcom/ui-components';
import { MediaQueryService } from '@fcom/common/services';
import { LocationRouteType } from '@fcom/core-api';
import { ConfigService } from '@fcom/core/services';
import { TripType } from '@fcom/core/constants';

import { ExtendedFareFamily, FareFamilyBadgeType } from '../../../interfaces';
import { shouldDisplayBenefit } from '../../../utils/benefit.utils';

@Component({
  selector: 'fin-fare-family-option',
  styleUrls: ['./fare-family-option.component.scss'],
  templateUrl: './fare-family-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirBoundsFareFamilyOptionComponent implements OnInit {
  expandOpen = false;

  @Input() context: string;

  @Input() accordionId: string;

  @Input() currencyCode$: Observable<string>;

  @Input()
  set fareFamily(fareFamily: ExtendedFareFamily) {
    this.filterBenefits(fareFamily);
  }

  @Input() routeType: LocationRouteType;

  @Input() isShortHaul: boolean;

  @Input() hasSpecialOffer = false;

  @Input() originCountryCode: string;

  @Input() destinationCountryCode: string;

  @Input() departureDate: string;

  @Input() tripType$: Observable<TripType> = EMPTY;

  @Output() selectFareFamily: EventEmitter<FinnairBoundFareFamily> = new EventEmitter();
  @Output() showDimensionsAndDetails: EventEmitter<FinnairBoundFareFamily> = new EventEmitter();

  extendedFareFamily: ExtendedFareFamily;
  highlightedBenefits: TranslatedBenefitRow[] = [];
  isMobile$: Observable<boolean>;
  enableLoyaltyAvios: boolean;
  enableBenefitChanges: boolean;

  readonly FareFamilyBadgeType = FareFamilyBadgeType;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(
    private mediaQueryService: MediaQueryService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
    const isAward = !!this.extendedFareFamily.points;
    this.enableLoyaltyAvios = this.configService.cfg.enableLoyaltyAvios.bookingFlowNotifications && !isAward;
    this.enableBenefitChanges = this.configService.cfg.enableTicketTypeCardChanges;
  }

  handleSelectFareFamilyClick(e: Event, { ...baseBoundFareFamily }: ExtendedFareFamily): void {
    stopPropagation(e);
    this.selectFareFamily.emit(baseBoundFareFamily as FinnairBoundFareFamily);
  }

  private filterBenefits(fareFamily: ExtendedFareFamily) {
    this.extendedFareFamily = fareFamily;
    this.highlightedBenefits = fareFamily.benefits.translatedBenefitRows.filter((benefit) =>
      shouldDisplayBenefit(benefit)
    );
  }

  toggleExpand(isOpen: boolean): void {
    this.expandOpen = isOpen;
  }

  handleShowDetails(fareFamily: ExtendedFareFamily): void {
    this.showDimensionsAndDetails.emit(fareFamily as FinnairBoundFareFamily);
  }
}
