import { Airline } from '@fcom/common';
import { FinnairCheckInEligibility } from '@fcom/dapi/api/models';

declare const jest: unknown;
/* eslint-disable @typescript-eslint/ban-types */

const SCROLL_CONTAINER = 'scroll';

/**
 * Does nothing.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop(): void {}

/**
 * Returns true when target is literal null
 * @param obj
 * @returns true, if target is null, false otherwise
 */
export const isNull = (obj: unknown): obj is null => obj === null && typeof obj === 'object';

/**
 * Returns tru when target is undefined
 * @param obj
 * @returns true, if target is undefined, false otherwise
 */
export const isUndefined = (obj: unknown): obj is undefined => typeof obj === 'undefined';

/**
 * Returns true when target is not undefined or null
 * @param obj
 */
export const isPresent = <T>(obj: T | undefined | null): obj is T => !isUndefined(obj) && !isNull(obj);

/**
 * Returns true when all targets are not undefined or null
 * @param targets
 */
export const arePresent = (...targets: unknown[]): boolean =>
  isNotEmpty(targets) && targets.every((target) => isPresent(target));
/**
 * Returns true when obj is a function, false otherwise
 * @param obj
 */
export const isFunction = (obj: unknown): boolean => typeof obj === 'function';

/**
 * Returns true when target is truthy
 * @param a target
 */
export const isTruthy = (a: unknown): boolean => !!a;

export const isFalsy = (a: unknown): boolean => !isTruthy(a);

/**
 * Returns true when input string is not defined, empty or it contains just whitespace characters
 * @param str
 */
export const isBlank = (str: string): boolean => !str || str.trim().length === 0;
/**
 * Returns true when input string is defined and it contains non-whitespace characters
 * @param str
 */
export const isNotBlank = (str: string): boolean => !isBlank(str);

export function isNotEmpty<T>(array: T[]): boolean {
  return Array.isArray(array) && array.length > 0;
}

export const isEmpty = (array: unknown[]): boolean => !isNotEmpty(array);

export const isObject = (obj: unknown): obj is object => isPresent(obj) && typeof obj === 'object';

export const isString = (obj: unknown): obj is string => isPresent(obj) && typeof obj === 'string';

export const compareAsJson = <T>(a: T, b: T): boolean => JSON.stringify(a) === JSON.stringify(b);

export const textMatch = (a: string, b: string): boolean => {
  return isNotBlank(a) && isNotBlank(b) && a.toLowerCase() === b.toLowerCase();
};

export const toBoolean = (value: unknown): boolean => value != null && String(value).toLowerCase() !== 'false';

export const isEmptyObjectOrHasEmptyValues = (obj: object): boolean => {
  const objectValues = valuesOf(obj as Record<string, unknown>);
  const emptyValues = objectValues.filter((val: unknown) => !isPresent(val) || (isString(val) && isBlank(val))).length;
  return obj && typeof obj === 'object' && (objectValues.length === 0 || emptyValues > 0);
};

export interface ValueIsTouched<T> {
  value: T;
  isTouched: boolean;
}

export const overrideIfNullOrEmpty = <T>(previousValue: T, newValue: T): ValueIsTouched<T> => {
  if (
    previousValue &&
    ((typeof previousValue === 'string' && isNotBlank(previousValue)) ||
      (typeof previousValue === 'object' && !isEmptyObjectOrHasEmptyValues(previousValue)))
  ) {
    return { value: previousValue, isTouched: false };
  }
  return { value: newValue, isTouched: true };
};

interface ToSortKey<T> {
  (obj: T): string | number | object;
}
/**
 * Return a copy of the array sorted by given property.
 * The sort is stable.
 *
 * @param array
 * @param toSortKey
 * @returns {T[]}
 */
export function sortBy<T>(array: Array<T>, ...toSortKey: ToSortKey<T>[]): T[] {
  const sortable = [...array];

  return sortable.sort((aVal: T, bVal: T) => {
    const aKeys = toSortKey.map((t) => t(aVal));
    const bKeys = toSortKey.map((t) => t(bVal));

    // Compare the keys in order to get an array like [0, 0, -1, 1]
    // The first non-zero value wins
    const comparedKeys: number[] = aKeys.map((a, i) => {
      const b = bKeys[i];
      if (isPresent(a) && !isPresent(b)) {
        return 1;
      }
      if (isPresent(b) && !isPresent(a)) {
        return -1;
      }
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    });

    return comparedKeys.reduce((prev: number, next: number) => (prev === 0 ? next : prev), 0);
  });
}

/**
 * Returns a copy of the array with items sorted in natural order.
 */
export const sorted = <T>(arr: T[]): T[] => {
  const newArr = [...arr];
  newArr.sort();
  return newArr;
};

/**
 * Can be used to filter unique values in an array.
 * Keeps the first occurrence
 *
 * Usage: [1,1,3,1,2,4,5].filter(unique)  => [1,3,2,4,5]
 */
export const unique = <A>(value: A, index: number, self: A[]): boolean => self.indexOf(value) === index;

/**
 * Can be used to filter unique values by, e.g., a contained field
 *
 * Usage: ['a','b','c','foo','barbaz'].filter(uniqueBy(a => a.length)) => ['a','foo','barbaz']
 */
export const uniqueBy = <A, B>(accessor: (a: A) => B): ((value: A, index: number, self: A[]) => boolean) => {
  let mapped: B[];
  return (value: A, index: number, self: A[]): boolean => {
    if (!mapped) {
      mapped = self.map(accessor);
    }
    return mapped.indexOf(accessor(value)) === index;
  };
};

/**
 * Returns values of object mapped from `Object.keys`. Similar to ES2017 Object.values
 * @param o
 * @return {[string,string,string,string,string]}
 */
export function valuesOf<T>(o: Record<string, T> = {}): T[] {
  return Object.keys(o).map((k) => o[k]);
}

/**
 * Returns values of string Enum as an array
 * @param o Enum
 * @return {['option1', 'option2']}
 */
export function enumValues<T extends Record<string, T[K]>, K extends keyof T>(o: T): T[K][] {
  return Object.keys(o).map((k) => o[k]);
}

/**
 * Returns entrySet of object mapped from `Object.keys`.
 * @param o
 * @return [{ key: string, value: any }, { key: string, value: any }]
 */
export function entrySet<T>(o: Record<string, T> = {}): { key: string; value: T }[] {
  return isPresent(o) ? Object.keys(o).map((k) => ({ key: k, value: o[k] })) : [];
}

export function isIn<T>(value: T, ...list: T[]): boolean {
  return isNotEmpty(list) && list.indexOf(value) >= 0;
}

/**
 * Checks if 2 given params are deeply equal
 * @param obj1
 * @param obj2
 * @returns true/false
 */
export function isDeepEqual<T>(obj1: T, obj2: T): boolean {
  // check the object type: If both are null or undefined, return true
  if (obj1 === obj2) {
    return true;
  }

  // if either is not an object, return false since they would have been equal if both were the same primitive
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    // check if arrays are same length
    if (obj1.length !== obj2.length) {
      return false;
    }

    // check each element in the array
    for (let i = 0; i < obj1.length; i++) {
      if (!isDeepEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  } else if (Array.isArray(obj1) || Array.isArray(obj2)) {
    // one is an array, the other is not
    return false;
  }

  // if both are objects (not arrays), compare each key and value
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // check if both have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // sort keys to ensure order doesn't affect the comparison
  keys1.sort((a, b) => a.localeCompare(b, 'en'));
  keys2.sort((a, b) => a.localeCompare(b, 'en'));

  // check if both objects have the same keys in the same order
  for (let i = 0; i < keys1.length; i++) {
    if (keys1[i] !== keys2[i]) {
      return false;
    }
  }

  // check each key value pair recursively
  for (const key of keys1) {
    if (!isDeepEqual((obj1 as unknown)[key], (obj2 as unknown)[key])) {
      return false;
    }
  }

  return true;
}

/**
 * Returns concatanation of baseUrl and parameters object separated by the defined joinChar
 * @param baseUrl
 * @param parameters
 * @param joinChar
 * @return string
 */
export function buildUrl(baseUrl = '', parameters: object, joinChar = '&'): string {
  let urlParamSeparator = '';
  if (!isBlank(baseUrl)) {
    if (baseUrl.indexOf('?') === -1) {
      urlParamSeparator = '?';
    } else {
      urlParamSeparator = joinChar;
    }
  }
  return (
    baseUrl +
    urlParamSeparator +
    Object.keys(parameters)
      .map((key) => `${key}=${String(parameters[key])}`)
      .join(joinChar)
  );
}

/**
 * Transforms an object's values returning an object with the same keys as the original one.
 * @param {IN} o the object to map
 * @param {(obj: T, key: K) => MAPPED} mapper the transformer function for a single value
 * @return {OUT} the transformed object
 */
export const mapValues = <
  IN extends object,
  K extends keyof IN,
  T extends IN[K],
  MAPPED,
  OUT extends { [k in keyof IN]: MAPPED },
>(
  o: IN,
  mapper: (obj: T, key: K) => MAPPED
): OUT =>
  (Object.keys(o) as K[]).reduce<OUT>((acc: OUT, cur: K) => {
    acc[cur] = mapper(o[cur] as T, cur) as OUT[K];
    return acc;
  }, {} as OUT);

/**
 * Combines objects array values as one. Usable for objects that contain arrays mapped by id field (e.g. {[id: string]: T[]} ).
 * @param tsArray
 * @returns Combined array. Never undefined.
 */
export const combine = <T>(tsArray: { [id: string]: T[] } = {}): T[] =>
  valuesOf(tsArray).reduce((reduced: T[], current: T[]) => [...reduced, ...current], []);

export const AVAILABLE_TAILS: string[] = [
  '3k',
  '9w',
  'a3',
  'aa',
  'ab',
  'af',
  'ai',
  'as',
  'ay',
  'az',
  'b6',
  'ba',
  'br',
  'bus',
  'ca',
  'cx',
  'cz',
  'ci',
  'dx',
  'ei',
  'fi',
  'fj',
  'ga',
  'hu',
  'hx',
  'ib',
  'ig',
  'jl',
  'jj',
  'jp',
  'jq',
  'ju',
  'ka',
  'ke',
  'kl',
  'km',
  'la',
  'lg',
  'lo',
  'mh',
  'mi',
  'mu',
  'n7',
  'nt',
  'ok',
  'ou',
  'oz',
  'pg',
  'pr',
  'ps',
  'qf',
  'qr',
  'r6',
  'rj',
  's7',
  'sb',
  'sq',
  'su',
  'tf',
  'tp',
  'ul',
  'uk',
  'v3',
  'vn',
  'wf',
  'xl',
  'ax',
  'yx',
  'mq',
  'oh',
  'oo',
  'pt',
  'cp',
  'yv',
  'fv',
  'a5',
  'wx',
  'wh',
  'fb',
  't3',
  'tk',
  'tn',
  'lv',
  'pv',
  'zt',
  'lm',
  'cj',
  'az',
  'pm',
  'i2',
  'xm',
  '4m',
  '5m',
  'lu',
  're',
  'qx',
  'yw',
  'lx',
  'lh',
  'sk',
  'bt',
  'os',
  'sn',
  'tg',
  'nz',
  'ek',
  'f7',
  'ho',
  'wy',
  'et',
  'sv',
  'at',
  '6i',
];

const ALL_AIRLINES = [
  {
    code: 'AS',
    name: 'Alaska Airlines',
    isFFMember: true,
    isPartner: false,
  },
  {
    code: 'AA',
    name: 'American Airlines',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'TF',
    name: 'Braathens Regional Airlines',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'BA',
    name: 'British Airways',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'CX',
    name: 'Cathay Pacific',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'AY',
    name: 'Finnair',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'IB',
    name: 'Iberia',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'JL',
    name: 'Japan Airlines',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'HO',
    name: 'Juneyao Airlines',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'MH',
    name: 'Malaysian Airlines',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'QF',
    name: 'Qantas Airlines',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'QR',
    name: 'Qatar Airways',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'AT',
    name: 'Royal Air Maroc',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'RJ',
    name: 'Royal Jordanian',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'S7',
    name: 'S7 Airlines',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'UL',
    name: 'Srilankan Airways',
    isFFMember: true,
    isPartner: true,
  },
  {
    code: 'LA',
    name: 'LATAM Airlines',
    isFFMember: false,
    isPartner: true,
  },
];

export const PARTNER_AIRLINES: Airline[] = ALL_AIRLINES.filter((airline) => airline.isPartner).map((airline) => {
  return {
    code: airline.code,
    name: `${airline.name} (${airline.code})`,
  };
});

export const FREQUENT_FLIER_AIRLINES: Airline[] = ALL_AIRLINES.filter((airline) => airline.isFFMember).map(
  (airline) => {
    return {
      code: airline.code,
      name: airline.name,
    };
  }
);

export const getAirlineNameByCode = (operatingAirlineCode: string): string => {
  return ALL_AIRLINES.find((airline) => airline.code === operatingAirlineCode)?.name || operatingAirlineCode;
};

export const resolveTailName = (operatingAirlineCode: string): string | undefined => {
  if (isPresent(operatingAirlineCode)) {
    const code: string = operatingAirlineCode.toLowerCase();
    if (AVAILABLE_TAILS.indexOf(code) !== -1) {
      return `tail_${code}`;
    }
  }
  return undefined;
};

export const resolveTailNames = (operatingAirlineCodes: string[]): string[] => {
  return operatingAirlineCodes
    ? operatingAirlineCodes.map((code: string) => resolveTailName(code) ?? '').filter(Boolean)
    : [];
};

export const resolveUniqueTailNames = (operatingAirlineCodes: string[]): string[] => {
  return resolveTailNames(operatingAirlineCodes).filter(unique);
};

export const EMAIL_PATTERN =
  '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-\\+]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';
export const EMAIL_REGEX =
  '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-\\+]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$';

export const PHONE_PATTERN = '^[1-9]{1}[0-9]{0,14}';
export const PHONE_PREFIX_PATTERN = /^[A-Za-z]{2}\|[0-9+]{1,5}/;
export const NAME_PATTERN = /^[a-z\-\sšœžÿ£¥ª¯³¹¿àáâãäåæçėèéêëìíîïñòóôõöøßùúûüýðþ'`´]{2,}$/i;
export const LATIN_ADDRESS_PATTERN = /^[a-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšž∂ð 0-9,.()/'-]+$/i;
export const LATIN_ALPHA_PATTERN = /^[a-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšž∂ð ]+$/i;
export const TRUE_PATTERN = 'true';
export const WORLDTRACER_PATTERN = /^[A-z\d]{10}$/;
export const PNR_PATTERN = /^[A-z2-9]{6}$/;
export const PNR_PATTERN_WITH_TRAILING_WHITESPACE = /^[A-z2-9]{6}\s*$/;
export const ETICKET_PATTERN = /^[0-9]{13}$/;
export const ADDRESS_PATTERN = /^[a-zA-Z0-9 ]*$/;
export const ZIP_CODE_PATTERN = /^[a-zA-Z0-9 ]*$/;
export const CITY_PATTERN = /^[a-zA-Z -]*$/;
export const DISCOUNT_CODE_PATTERN = /^[A-Z0-9\-_]{2,15}$/;
export const GIFT_CARD_PATTERN = /^\d{16}$/;
export const GIFT_CARD_ID_PATTERN = /^(?:\d\s?){16}$/;
export const GIFT_CARD_PIN_PATTERN = /^\d{4}$/;
export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,32}$/;

export const rangeFrom = (startWith: number, length: number): number[] =>
  Array.from({ length }).map((_v, idx) => idx + startWith);

export const rangeBetween = (startInclusive: number, endInclusive: number): number[] =>
  Array.from({ length: endInclusive - startInclusive + 1 }).map((_v, idx) => idx + startInclusive);

export const restrictToMinMax = (value: number, min: number, max: number): number => {
  if (max < min) {
    throw new Error(`max ${max} should be greater or equal to min ${min}`);
  }
  if (value > max) {
    return max;
  } else if (value < min) {
    return min;
  }
  return value;
};

/**
 * Logs given object and returns it
 * @param obj object to be logged and chained
 * @param message optional message
 * @returns {T} first parameter
 */
export const log = <T>(obj: T, message?: string): T => {
  if (message) {
    console.log(message, obj);
  } else {
    console.log(obj);
  }
  return obj;
};

/**
 * Get the value given by `mapperFn` null-safely from the object `obj`.
 */
export function safeMap<A, B>(obj: A, map1: (o: A) => B): B;
export function safeMap<A, B, C>(obj: A, map1: (o: A) => B, map2: (o: B) => C): C;
export function safeMap<A, B, C, D>(obj: A, map1: (o: A) => B, map2: (o: B) => C, map3: (o: C) => D): D;
export function safeMap<A, B, C, D, E>(
  obj: A,
  map1: (o: A) => B,
  map2: (o: B) => C,
  map3: (o: C) => D,
  map4: (o: D) => E
): E;

// @ts-ignore
export function safeMap<A, B>(obj: A, ...mapperFns: Function[]): B {
  return mapperFns.reduce((acc: unknown, cur: Function) => {
    return acc ? cur(acc) : undefined;
  }, obj);
}

/**
 * Type-safe utility for creating curried version of safeMap.
 * Useful in, e.g., selectors.
 */
export function safeMapFrom<A, B>(map1: (o: A) => B): (o: A) => B;
export function safeMapFrom<A, B, C>(map1: (o: A) => B, map2: (o: B) => C): (o: A) => C;
export function safeMapFrom<A, B, C, D>(map1: (o: A) => B, map2: (o: B) => C, map3: (o: C) => D): (o: A) => D;
export function safeMapFrom<A, B, C, D, E>(
  map1: (o: A) => B,
  map2: (o: B) => C,
  map3: (o: C) => D,
  map4: (o: D) => E
): (o: A) => E;
export function safeMapFrom(...mapperFns: Function[]) {
  return (o: unknown): unknown => mapperFns.reduce((acc: unknown, cur: Function) => safeMap(acc, (a) => cur(a)), o);
}

// using String.prototype.match, the id is available in match[2]
export const YOUTUBE_ID_PATTERN = /^.*(youtu.be\/|v\/|vi\/|e\/|u\/\w+\/|embed\/|v=|vi=)([^#&?]*).*/;
export const YOUTUBE_URL_PATTERN = /\/\/(www\.)?(youtube\.com|youtu\.be|youtube-nocookie\.com)/;

export const youtubeIdFromUrl = (url: string): string | null => {
  const matches = url.match(YOUTUBE_ID_PATTERN);
  const isYoutube = url.match(YOUTUBE_URL_PATTERN);
  return isYoutube && matches ? matches[2] : null;
};

export const capitalizeWord = (txt: string): string => txt && txt[0].toUpperCase() + txt.slice(1);
/**
 * Convert StRiNGs to Capitalized Form.
 */
export const capitalize = (txt: string): string => txt?.toLowerCase()?.replace(/\b\S+\b/g, capitalizeWord);

// Finnair Plus cards always 9 digits and always and starts with 6
// allow to start with AY and to use space or - delimeter
const FINNAIR_CARD_FORMAT = /^(AY[ -]?)?[6-9][0-9]{2}[ -]?[0-9]{3}[ -]?[0-9]{3}$/;
const luhnTimesTable = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

/**
 * Validates the Finnair Plus Card number format
 *
 * @param cardNumber the Finnair Plus card number
 * @return True if valid, false if not valid
 */
export const isFinnairPlusCardNumber = (cardNumber: string): boolean => {
  if (!FINNAIR_CARD_FORMAT.test(cardNumber)) {
    return false;
  }

  const trimmedCardNumber: string = cardNumber.replace(/\D/g, '');

  let length: number = trimmedCardNumber.length;
  let even = true;
  let sum = 0;
  let value: number;

  while (length > 0) {
    length--;
    value = parseInt(trimmedCardNumber.charAt(length), 10);
    sum += (even = !even) ? luhnTimesTable[value] : value; // eslint-disable-line
  }

  return sum % 10 === 0;
};

export const formatFinnairPlusCardNumber = (cardNumber: string): string => {
  return cardNumber ? cardNumber.trim().toUpperCase() : '';
};

export const isNonFinnairPlusCardNumber = (cardNumber: string): boolean => {
  const regex = /^[a-zA-Z0-9-:]{2,20}$/;
  return regex.test(cardNumber);
};

export const isFrequentFlyerNumber = (airline: string, cardNumber: string): boolean => {
  if (airline === 'AY') {
    return isFinnairPlusCardNumber(cardNumber);
  }
  return isNonFinnairPlusCardNumber(cardNumber);
};

/**
 * Validates if flight is operated by finnair or norra, or is wet leased ("British Airways for Finnair")
 * @param operatingAirlineName
 * @param operatingAirlineCode
 * @returns {boolean}
 */
export const isFinnairNorraOrWetLease = (operatingAirlineName: string, operatingAirlineCode: string): boolean => {
  return (
    (isPresent(operatingAirlineName) && operatingAirlineName.toLocaleLowerCase().includes('for finnair')) ||
    ['AY', 'N7'].includes(operatingAirlineCode)
  );
};

/**
 * Validates PNR code
 * @param value
 * @returns {boolean}
 */
export const isPNRCode = (value: string): boolean => {
  return /^[A-Z0-9]{5,6}$/.test(value);
};

/**
 * Validates Amadeus PNR code
 * @param value
 * @returns {boolean}
 */
export const isAmadeusPNRCode = (value: string): boolean => {
  return /^[A-Z0-9]{6}$/.test(value);
};

const regExp = (className: string): RegExp => new RegExp(`(^| )${className}( |$)`, 'g');
const getClassesFor = (element: Element): string => element.getAttribute('class') || '';
export const addClass = (element: Element, ...classNames: string[]): void => {
  element.classList
    ? element.classList.add(...classNames)
    : classNames
        .filter((className: string) => !containsClass(element, className))
        .forEach((className: string) =>
          element.setAttribute(
            'class',
            getClassesFor(element).length > 0 ? `${getClassesFor(element)} ${className}` : className
          )
        );
};
export const removeClass = (element: Element, ...classNames: string[]): void => {
  element.classList
    ? element.classList.remove(...classNames)
    : classNames.forEach((className: string) =>
        element.setAttribute('class', getClassesFor(element).replace(regExp(className), ' ').replace(/ +/g, ' ').trim())
      );
};
export const containsClass = (element: Element, className: string): boolean => {
  return regExp(className).test(element.getAttribute('class') ?? '');
};

export const isVisible = (element: HTMLElement): boolean => {
  return typeof jest !== 'undefined'
    ? true
    : !containsClass(element, 'is-hidden') &&
        !containsClass(element, 'is-vishidden') &&
        !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};

export const hyphenate = (camelCaseString: string): string =>
  camelCaseString?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

/**
 * Returns a hash code for a string
 * @param {string} str
 * @return {string}
 */
export function stringHashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    /* eslint-disable no-bitwise */
    hash = (hash << 5) - hash + chr;
    hash |= 0;
    /* eslint-enable no-bitwise */
  }
  return hash;
}

export function deepCopy<T>(obj: T): T {
  let copy: T;
  // Handle the 3 simple types, and null or undefined
  if (null === obj || 'object' !== typeof obj) {
    return obj;
  }
  // Handle Date
  if (obj instanceof Date) {
    // @ts-ignore
    copy = new Date();
    // @ts-ignore
    copy.setTime(obj.getTime());
    return copy;
  }
  // Handle Array
  if (obj instanceof Array) {
    // @ts-ignore
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      // @ts-ignore
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }
  // Handle Object
  if (obj instanceof Object) {
    // @ts-ignore
    copy = {};
    for (const attr in obj) {
      /* eslint-disable no-prototype-builtins */
      // @ts-ignore
      if (obj.hasOwnProperty(attr)) {
        // @ts-ignore
        copy[attr] = deepCopy(obj[attr]);
      }
      /* eslint-enable no-prototype-builtins */
    }
    return copy;
  }
  // eslint-disable-next-line quotes
  throw new Error("Unable to copy obj! Its type isn't supported.");
}

/**
 * Validate IBAN as described here https://en.wikipedia.org/wiki/International_Bank_Account_Number#Validating_the_IBAN
 * This validator doesn't do country specific length checking
 * @param str
 */
export const isValidIBAN = (str: string): boolean => {
  // Move the four initial characters to the end of the string
  const reordered = str.substring(4) + str.substring(0, 4);
  // Replace each letter in the string with two digits, thereby expanding the string, where A = 10, B = 11, ..., Z = 35
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const replaced = reordered
    .split('')
    .map((char) => (alphabet.indexOf(char) !== -1 ? alphabet.indexOf(char) + 10 : char))
    .join('');
  // Interpret the string as an integer and compute the remainder of that number on division by 97
  return pieceWiseModulo(replaced, 97) === 1;
};

// Calculates modulo for string representations of big integers
const pieceWiseModulo = (num: string, divider: number): number =>
  +(num.match(/.{1,7}/g)?.reduce((prev: string, curr: string) => `${+(prev + curr) % divider}`, '') ?? '');

export const findScrollContainer = (element: HTMLElement): HTMLElement | null => {
  let currentElement = element.parentElement;
  while (currentElement && !currentElement.classList.contains(SCROLL_CONTAINER)) {
    currentElement = currentElement.parentElement;
  }

  return currentElement;
};

export const serializeParams = (params: { [key: string]: string }): string => {
  return entrySet(params)
    .map(({ key, value }) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
};

export const isFinnairCustomerNumber = (value: string): boolean => {
  // Regex is from the old site
  return (
    value.length === 8 &&
    /^[a-zA-Z][1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]+[a-zA-Z]$/.test(value)
  );
};

/**
 * Returns a new object with the specified key removed.
 * @param {T} obj - The object to remove the key from.
 * @param {K} key - The key to remove from the object.
 * @returns A new object with the specified key removed.
 */
export const omitProperty = <T, K extends keyof T>(obj: T, key: K): Omit<T, K> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: _, ...rest } = obj;
  return rest;
};

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> =>
  list?.reduce(
    (previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) {
        previous[group] = [];
      }
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>
  ) ?? ({} as Record<K, T[]>);

export const toLookup = <T, K extends keyof any>(arr: T[], getKey: (t: T) => K): Record<K, T> => {
  if (!arr || !getKey) {
    return {} as Record<K, T>;
  }
  return (
    getKey &&
    arr?.reduce(
      (lookup, item) => {
        const objKey = getKey(item);
        lookup[objKey] = item;
        return lookup;
      },
      {} as Record<K, T>
    )
  );
};

export function passengersNotCheckedInExist(
  flightTravelerEligibility: FinnairCheckInEligibility['flightTravelerEligibility']
): boolean {
  return Object.values(flightTravelerEligibility ?? {}).some((eligibility) =>
    eligibility.some((e) => e.isAllowedToUseCheckIn && !e.isCheckedIn)
  );
}
