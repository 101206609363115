import { Injectable } from '@angular/core';

import { iif, Observable, of } from 'rxjs';
import { catchError, map, share, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ConfigService, SentryLogger } from '@fcom/core/services';
import {
  AirCalendarList,
  Cabin,
  FinnairAirBoundsRequest,
  FinnairAirBoundsResponse,
  FinnairRequestItineraryItem,
  FinnairSearchParameters,
  LowestPriceOfPeriod,
  OfferList,
  StatusCode,
} from '@fcom/dapi/api/models';
import { finShare, retryWithBackoff } from '@fcom/rx';
import { mapErrorForSentry, TripType } from '@fcom/core';
import { InstantsearchService, OffersService } from '@fcom/dapi/api/services';
import { isNotEmpty, isPresent } from '@fcom/core/utils';
import { LanguageService } from '@fcom/ui-translate';
import { HistogramBar } from '@fcom/common';
import { BookingAppState } from '@fcom/common/interfaces/booking';

import { notificationWarnings } from '../store';
import {
  CalendarPrices,
  FlightSearchParams,
  InstantSearchFollowingMonthParams,
  InstantSearchFullYearParams,
  NotificationWarning,
  SeasonalNotificationData,
  WarningData,
} from '../interfaces';
import {
  createHistogramData,
  getBookingWidgetCabinClass,
  mapInstantSearchPricesToCalendarPrices,
  seasonalNotificationHandler,
} from '../utils/utils';
import { BookingWidgetActions } from '../store/actions';

@Injectable()
export class BookingWidgetCalendarService {
  static NUMBER_OF_RETRIES = 2;

  constructor(
    private store$: Store<BookingAppState>,
    private languageService: LanguageService,
    private configService: ConfigService,
    private offersService: OffersService,
    private instantsearchService: InstantsearchService,
    private sentryLogger: SentryLogger
  ) {}

  getAirCalendar(params: FlightSearchParams): Observable<AirCalendarList> {
    const airCalendarRequest = this.createOffersRequest(params);
    return this.offersService.findAirCalendar(this.configService.cfg.offersUrl, { body: airCalendarRequest }).pipe(
      switchMap((airCalendarList: AirCalendarList) => {
        if (isPresent(airCalendarList.airCalendars)) {
          this.store$.dispatch(BookingWidgetActions.setAirCalendarPrices({ prices: airCalendarList }));
          return of(airCalendarList);
        } else if (params.isAward) {
          const instantSearchParams: InstantSearchFollowingMonthParams = {
            departureDate: params.flights[0]?.departureDate.toISOString().substring(0, 10),
            departureLocationCode: params.flights[0]?.origin.locationCode,
            destinationLocationCode: params.flights[0]?.destination.locationCode,
            numberOfDays: 30,
            oneway: params.tripType === TripType.ONEWAY,
            locale: params.locale,
            directFlights: false,
          };

          return this.getPricesForFollowingMonth(instantSearchParams).pipe(
            map((prices) => {
              this.setNotificationWarning(
                Object.entries(prices || {}).length === 0
                  ? NotificationWarning.NO_FLIGHTS
                  : NotificationWarning.NO_AWARD_FLIGHTS
              );
              return { airCalendars: undefined, status: StatusCode.NO_FLIGHTS_FOUND, messages: [] };
            })
          );
        } else {
          this.setNotificationWarning(NotificationWarning.NO_FLIGHTS);
          return of({ airCalendars: undefined, status: StatusCode.NO_FLIGHTS_FOUND, messages: [] });
        }
      }),
      retryWithBackoff(BookingWidgetCalendarService.NUMBER_OF_RETRIES),
      catchError((err: unknown) => {
        this.sentryLogger.error('Error finding air calendar', {
          error: mapErrorForSentry(err),
        });

        this.setNotificationWarning(NotificationWarning.GENERAL);

        return of({ airCalendars: undefined, status: StatusCode.NO_FLIGHTS_FOUND, messages: [] });
      }),
      share()
    );
  }

  getPricesForFullYear(
    params: InstantSearchFullYearParams
  ): Observable<{ fullYear: CalendarPrices; histogram: HistogramBar[] }> {
    return this.instantsearchService.getPricesForPeriod(this.configService.cfg.instantSearchUrl, params).pipe(
      withLatestFrom(this.languageService.translate('date')),
      map(([response, dateTranslations]: [LowestPriceOfPeriod, Record<string, string>]) => {
        const fullYear = mapInstantSearchPricesToCalendarPrices(response);
        const histogram = createHistogramData(response, Object.keys(fullYear), dateTranslations);

        if (isNotEmpty(response?.availability)) {
          const seasonalNotificationData = seasonalNotificationHandler(response.availability, dateTranslations);
          this.setNotificationWarning(NotificationWarning.SEASONAL_ROUTE, true, seasonalNotificationData);
        }

        return {
          fullYear,
          histogram,
        };
      }),
      retryWithBackoff(BookingWidgetCalendarService.NUMBER_OF_RETRIES),
      catchError(() => of({ fullYear: {}, histogram: [] })),
      finShare()
    );
  }

  getPricesForFollowingMonth(params: InstantSearchFollowingMonthParams): Observable<CalendarPrices> {
    return this.instantsearchService
      .getPricesForPeriodWithFixedDepartureDate(this.configService.cfg.instantSearchUrl, params)
      .pipe(
        map((response: LowestPriceOfPeriod) => mapInstantSearchPricesToCalendarPrices(response)),
        retryWithBackoff(BookingWidgetCalendarService.NUMBER_OF_RETRIES),
        catchError((err: unknown) => {
          this.sentryLogger.error('Error finding prices for following month', {
            error: mapErrorForSentry(err),
          });
          return of({});
        }),
        finShare()
      );
  }

  checkFlightAvailabilityAndContinue(params: FlightSearchParams): Observable<boolean> {
    return iif(
      () => params.isAward,
      this.checkOffersAvailability(params),
      this.checkAirboundsAvailability(params)
    ).pipe(
      withLatestFrom(this.store$.pipe(notificationWarnings(), finShare())),
      map(([response, storeNotificationMap]: [OfferList | FinnairAirBoundsResponse, Record<string, WarningData>]) => {
        if (!response || !response?.status) {
          this.setNotificationWarning(NotificationWarning.GENERAL);
          return false;
        }

        if (storeNotificationMap[NotificationWarning.GENERAL]?.isActive) {
          // remove the general notification if stored previously
          this.setNotificationWarning(NotificationWarning.GENERAL, false);
        }

        const hasAvailability = ![StatusCode.NO_FLIGHTS_FOUND, StatusCode.NO_FLIGHTS_LEFT_FOR_TODAY].includes(
          response.status
        );

        if (!hasAvailability) {
          this.setNotificationWarning(NotificationWarning.NO_FLIGHTS);
        }
        return hasAvailability;
      })
    );
  }

  checkOffersAvailability(params: FlightSearchParams): Observable<OfferList | undefined> {
    const offersRequest: FinnairSearchParameters = this.createOffersRequest(params);

    return this.offersService
      .findOfferList(this.configService.cfg.offersUrl, { body: offersRequest })
      .pipe(share())
      .pipe(
        retryWithBackoff(BookingWidgetCalendarService.NUMBER_OF_RETRIES),
        catchError((err: unknown) => {
          this.sentryLogger.error('No flights available for selection', {
            error: mapErrorForSentry(err),
          });

          return of(undefined);
        }),
        finShare()
      );
  }

  checkAirboundsAvailability(params: FlightSearchParams): Observable<FinnairAirBoundsResponse | undefined> {
    const airBoundsRequest: FinnairAirBoundsRequest = this.createAirboundsRequest(params);
    return this.offersService
      .findAirBounds(this.configService.cfg.offersUrl, { body: airBoundsRequest })
      .pipe(share())
      .pipe(
        retryWithBackoff(BookingWidgetCalendarService.NUMBER_OF_RETRIES),
        catchError((err: unknown) => {
          this.sentryLogger.error('No flights available for selection', {
            error: mapErrorForSentry(err),
          });
          return of(undefined);
        }),
        finShare()
      );
  }

  private setNotificationWarning(type: NotificationWarning, isActive = true, data?: SeasonalNotificationData): void {
    this.store$.dispatch(
      BookingWidgetActions.setNotificationWarning({
        key: type,
        isActive,
        data: data,
      })
    );
  }

  private createOffersItinerary(params: FlightSearchParams): FinnairRequestItineraryItem[] {
    const { flights } = params;

    return flights.map((flight) => ({
      departureDate: flight.departureDate?.id,
      departureLocationCode: flight.origin?.locationCode,
      destinationLocationCode: flight.destination?.locationCode,
    }));
  }

  private createOffersRequest(params: FlightSearchParams): FinnairSearchParameters {
    const itinerary = this.createOffersItinerary(params);
    return {
      adults: params.paxAmount.adults,
      c15s: params.paxAmount.c15s,
      cabin: getBookingWidgetCabinClass(
        params.travelClass,
        params.isCorporate,
        this.configService.cfg.enableCorporateTravelClassLimit
      ) as string as Cabin,
      children: params.paxAmount.children,
      infants: params.paxAmount.infants,
      isAward: params.isAward,
      itinerary,
      locale: params.locale,
      promoCode: params.promoCode,
    };
  }

  private createAirboundsRequest({
    paxAmount,
    flights,
    travelClass,
    isCorporate,
  }: FlightSearchParams): FinnairAirBoundsRequest {
    return {
      cabin: getBookingWidgetCabinClass(
        travelClass,
        isCorporate,
        this.configService.cfg.enableCorporateTravelClassLimit
      ) as string as Cabin,
      locale: this.languageService.localeValue,
      travelers: paxAmount,
      itineraries: flights.map((flight, i) => ({
        departureDate: flight.departureDate.id,
        departureLocationCode: flight.origin.locationCode,
        destinationLocationCode: flight.destination.locationCode,
        directFlights: false,
        isRequestedBound: i === 0,
      })),
    };
  }
}
