/* eslint-disable camelcase */
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { BreadcrumbItem, CmsPageComponentMetaData } from '@fcom/common';

import { LoginStatus, ProfileTier } from '../login';

// FIXME: split CMS specific interfaces to cms module
export enum CmsContentType {
  CMRelated = 'CMP13NSearch',
  CMChannel = 'CMChannel',
  CMArticle = 'CMArticle',
  CMCollection = 'CMCollection',
  CMExternalLink = 'CMExternalLink',
  CMPicture = 'CMPicture',
  CMPersonalized = 'CMSelectionRules',
  CMPlaceholder = 'CMPlaceholder',
  CMQueryList = 'CMQueryList',
  CMTeaser = 'CMTeaser',
  CMVideo = 'CMVideo',
  FICategory = 'FICategory',
  FIDestination = 'FIDestination',
  FIOffer = 'FIOffer',
  FIMenuFoodItem = 'FIMenuFoodItem',
}

export enum CmsViewType {
  ACCORDION = 'accordion',
  ACCORDION_WITH_PICTURES = 'accordion-with-pictures',
  ARTICLE_COLLECTION = 'article-collection',
  ARTICLES_WITH_ICONS = 'articles-with-icons',
  BRAND_LANDING_PAGE_FOOTER = 'brand-landing-page-footer',
  BRAND_LANDING_PAGE_HERO = 'brand-landing-page-hero',
  BLOCK_OFFER = 'block-offer',
  CAROUSEL = 'carousel',
  COLLAPSE_RELATED_CONTENT = 'collapse-related-content',
  CONDITIONAL_ACCORDION = 'conditional-accordion',
  CONTENT_DROPDOWN = 'content-switcher-dropdown',
  CONTENT_TABS = 'content-switcher-tabs',
  CORPORATE_CONTACT_FORM = 'corporate-contact-form',
  CTA_WITH_IMAGE_AND_TEXT = 'cta-with-image-and-text',
  CTA_BIG_LINK = 'cta-big-link',
  CTA_LINK = 'cta-link',
  DECORATED_HEADING = 'decorated-heading',
  DESCRIPTIVE_LINK_LIST = 'descriptive-link-list',
  DESTINATIONS_SEARCH = 'destinations-search',
  DISCLAIMER = 'disclaimer-block',
  DISRUPTIONS = 'disruptions',
  DISRUPTION_LEVEL_1 = 'disruption-level-1',
  DISRUPTION_LEVEL_2 = 'disruption-level-2',
  DISRUPTION_LEVEL_3 = 'disruption-level-3',
  DISRUPTION_LEVEL_4 = 'disruption-level-4',
  FRONT_PAGE_ARTICLES = 'front-page-articles',
  FRONT_PAGE_DISRUPTIONS = 'front-page-disruptions',
  FRONT_PAGE_LINKS = 'front-page-links',
  FRONT_PAGE_OFFER = 'front-page-offer',
  GIFT_CARD_INFORMATION_FORM = 'gift-card-information-form',
  HERO = 'hero',
  HIGHLIGHT_LIST = 'highlight-list',
  HORIZONTAL_RULER = 'horizontal-ruler',
  HORIZONTAL_TITLE = 'horizontal-title',
  ICON_WITH_TEXT = 'icon-with-text',
  IMAGE_BANNER = 'image-banner',
  IMAGE_RIGHT_BANNER = 'image-right-banner',
  IMAGE_GRID_BANNER = 'image-grid-banner',
  INTRODUCTION_BANNER = 'introduction-banner',
  ITEMS = 'items',
  JOIN_CORPORATE_FORM = 'join-corporate-form',
  LINK_BLOCK = 'link',
  LINK_LIST_WITH_ICONS = 'link-list-with-icons',
  LIST_WITH_ICONS = 'list-with-icons',
  LIST_WITH_ICONS_TEASERTEXT = 'list-with-icons-teasertext',
  LIST_WITH_HEADING = 'list-with-heading',
  OFFERS_GRID = 'offers-grid',
  OND_OFFERS_LIST = 'ond-offers-list',
  OND_CROSSLINKING = 'ond-crosslinking',
  OND_OFFERS = 'ond-offers',
  SAF_DOWNLOADABLE_FORM = 'saf-downloadable-form',
  SHOW_MORE = 'show-more',
  TEXT_LINK = 'text-link',
  TEXT_ON_IMAGE = 'text-on-image',
  TEXT_ON_VIDEO = 'text-on-video',
  TEXT_WITH_SIDE_IMAGE = 'text-with-side-image',
  TRAVEL_GUIDE = 'travel-guide',
  BADGE = 'badge',
  SECTION_NAVI = 'section-one-third-left--navi',
  UNIQUE_SELLING_POINT = 'front-page-usps',
  TEASER_CAROUSEL = 'teaser-carousel',
  MAIN_ARTICLE = 'main-article',
  BACK_LINK = 'back-link',
  TEASER_LIST = 'teaser-list',
  BLUEWINGS_ARTICLE = 'bluewings-article',
  CHILD_ARTICLE = 'child-article',
  TEASER_LIST_HIGHLIGHT = 'teaser-list-highlight',
  TAG_LIST = 'tag-list',
  RELATED_LIST = 'related-list',
  LOCATION_BANNER = 'location-banner',
  INFORMATIONAL_TEASER = 'informational-teaser',
  RADIO_COLLECTION = 'radio-collection',
  DESTINATION_SEARCH_HERO = 'destination-search-hero',
  RETRIEVE_PAGE_CONTENT = 'retrieve-page-content',
  TRAVEL_RESTRICTIONS = 'smartvel-travel-restrictions',
  FULL_WIDTH_BOOKING_WIDGET = 'full-width-booking-widget',
  DESTINATION_TITLE = 'destination-title',
  TRENDING_DESTINATIONS = 'trending-destinations',
  NEWSLETTER_SIGNUP_FORM = 'newsletter-signup-form',
  VAT_RECEIPT_FORM = 'vat-ticket-receipt-form',
  CASE_ATTACHMENT_FORM = 'case-attachments-form',
  POPUP = 'popup',
}

export enum TemplateName {
  CMS_DESTINATION_HERO = 'cms-destination-hero',
  CMS_TRAVEL_GUIDE = 'cms-travel-guide',
  CMS_ITEMS = 'cms-items',
}

export enum CmsViewTypeWithGrid {
  SECTION_FULL_BLEED_OVERLAY = 'section-full-bleed--overlay',
  SECTION_FULL_WIDTH = 'section-full-width',
  SECTION_FULL_WIDTH_DECORATED_HEADING = 'section-full-width--decorated-heading',
  SECTION_FULL_WIDTH_FRONT_PAGE_USPS = 'section-full-width--front-page-usps',
  SECTION_FULL_WIDTH_FRONT_PAGE_ARTICLES = 'section-full-width--front-page-articles',
  SECTION_FULL_WIDTH_FRONT_PAGE_DISRUPTIONS = 'section-full-width--front-page-disruptions',
  SECTION_FULL_WIDTH_FRONT_PAGE_LINKS = 'section-full-width--front-page-links',
  SECTION_FULL_WIDTH_OFFERS_GRID = 'section-full-width--offers-grid',
  SECTION_FULL_WIDTH_OVERLAY = 'section-full-width--overlay',
  SECTION_FULL_WIDTH_TEXT_LINK = 'section-full-width--text-link',
  SECTION_ONE_THIRD_LEFT = 'section-one-third-left',
  SECTION_ONE_THIRD_LEFT_NAVI = 'section-one-third-left--navi',
  SECTION_ONE_THIRD_RIGHT = 'section-one-third-right',
  SECTION_TWO_THIRDS_RIGHT = 'section-two-thirds-right',
  SECTION_TWO_THIRDS_RIGHT_ACCORDION = 'section-two-thirds-right--accordion',
  SECTION_TWO_THIRDS_RIGHT_BADGE = 'section-two-thirds-right--badge',
  SECTION_TWO_THIRDS_RIGHT_CONDITIONAL_ACCORDION = 'section-two-thirds-right--conditional-accordion',
  SECTION_TWO_THIRDS_RIGHT_CTA_BIG_LINK = 'section-two-thirds-right--cta-big-link',
  SECTION_TWO_THIRDS_RIGHT_CTA_LINK = 'section-two-thirds-right--cta-link',
  SECTION_TWO_THIRDS_RIGHT_DISCLAIMER_BLOCK = 'section-two-thirds-right--disclaimer-block',
  SECTION_TWO_THIRDS_RIGHT_ICON_WITH_TEXT = 'section-two-thirds-right--icon-with-text',
  SECTION_TWO_THIRDS_RIGHT_LINK = 'section-two-thirds-right--link',
  SECTION_TWO_THIRDS_RIGHT_OVERLAY = 'section-two-thirds-right--overlay',
  SECTION_TWO_THIRDS_RIGHT_SEPARATOR = 'section-two-thirds-right--separator',
  SECTION_TWO_THIRDS_RIGHT_SPECIAL_ASSISTANCE_FORM = 'section-two-thirds-right--special-assistance-form',
}

export enum CmsFormType {
  BAGGAGE_TRACING_FORM = 'baggage-tracing-form',
  CANCELLATION_COVER_CLAIM_FORM = 'cancellation-cover-claim-form',
  CASE_ATTACHMENT_FORM = 'case-attachments-form',
  CERTIFICATES_FOR_INSURANCE_COMPANY_FORM = 'certificates-for-insurance-company-form',
  CLAIM_MISSING_PARTNER_POINTS_FORM = 'claim-missing-partner-points-form',
  CLAIM_MISSING_POINTS_FORM = 'claim-missing-points-form',
  CORPORATE_CONTACT_FORM = 'corporate-contact-form',
  E_TICKET_RECEIPT_FORM = 'e-ticket-receipt-form',
  GIFT_CARD_INFORMATION_FORM = 'gift-card-information-form',
  JOIN_CORPORATE_FORM = 'join-corporate-form',
  REFUND_FORM = 'refund-form',
  REFUND_STATUS_CHECKER_FORM = 'refund-status-checker-form',
  SAF_DOWNLOADABLE_FORM = 'saf-downloadable-form',
  SPECIAL_ASSISTANCE_FORM = 'special-assistance-form',
  SPECIAL_ASSISTANCE_WIZARD = 'special-assistance-wizard',
  TRAVEL_CLASS_UPGRADE_FORM = 'travel-class-upgrade-form',
  VAT_RECEIPT_FORM = 'vat-ticket-receipt-form',
}

export enum PageViewType {
  SECTION_PAGE_WITH_TITLE = 'section-page-with-title',
  SECTION_PAGE_EXTERNAL_TEMPLATE = 'section-page-external-template',
  DESTINATION_PAGE = 'destination-page',
  NO_GRID_FULL_WIDTH_PAGE = 'no-grid-full-width-page',
  NO_GRID_FULL_WIDTH_PAGE_WITH_TITLE = 'no-grid-full-width-page-with-title',
  EDITORIAL_LANDING_PAGE = 'editorial-landing-2020',
  EDITORIAL_CATEGORY_PAGE = 'editorial-category-2020',
  BLUEWINGS_LANDING_PAGE = 'bluewings-landing-2020',
  FORM_WIZARD_PAGE = 'form-wizard',
  CHILD_PAGE = 'child-page-2020',
  CHILD_PAGE_NO_HEADING = 'child-page-2020-no-heading',
  PREMIUM_ECONOMY_LANDING_PAGE_2021 = 'premium-economy-landing-page-2021',
}

export enum CmsTaxonomyGeneral {
  ARROW_LINK = 'cmstyle-arrow-link',
  BANNER_LARGE_SLOGAN = 'cmstyle-banner-large-slogan',
  BUTTON_LINK = 'cmstyle-button-link',
  CENTERED = 'cmstyle-centered',
  CHECKED_LIST = 'cmstyle-checked-list',
  ROUNDED_TABLE = 'cmstyle-rounded-table',
  NO_TITLE = 'cmsetting-no-title',
  NO_SECTION_MARKER = 'cmstyle-no-section-marker',
  NO_PADDING = 'cmstyle-no-padding',
  ICE_10_BG = 'cmstyle-ice-10-bg',
  SECTION = 'cmstyle-section',
  SECTION_ONE_THIRD_LEFT = 'cmstyle-section-one-third-left',
  SECTION_ONE_THIRD_RIGHT = 'cmstyle-section-one-third-right',
  SECTION_TWO_THIRDS_RIGHT = 'cmstyle-section-two-thirds-right',
  SECTION_ONE_FOURTH_LEFT = 'cmstyle-section-one-fourth-left',
  SECTION_ONE_FOURTH_RIGHT = 'cmstyle-section-one-fourth-right',
  SECTION_THREE_FOURTHS_RIGHT = 'cmstyle-section-three-fourths-right',
  SHOW_FOR_LOGGED_IN = 'cmsetting-logged-in',
  SHOW_FOR_NOT_LOGGED_IN = 'cmsetting-not-logged-in',
  SHOW_FOR_LOGGED_IN_PLUS = 'cmsetting-logged-in-plus',
  HIDE_FROM_MAIN_MENU = 'cmsetting-navi-hide-from-main-menu',
  HIDE_FROM_SECTION_NAVI = 'cmsetting-navi-hide-from-section-navi',
  BREADCRUMB_APPLICATION_OVERRIDE = 'cmsetting-navi-breadcrumb-application-override',
  BLACK_FRIDAY = 'cmstyle-black-friday',
  SHOW_FOR_BASIC_TIER = 'cmsetting-tier-basic',
  SHOW_FOR_SILVER_TIER = 'cmsetting-tier-silver',
  SHOW_FOR_GOLD_TIER = 'cmsetting-tier-gold',
  SHOW_FOR_PLATINUM_TIER = 'cmsetting-tier-platinum',
  SHOW_FOR_PLATINUM_LUMO_TIER = 'cmsetting-tier-lumo',
  SHOW_FOR_JUNIOR_TIER = 'cmsetting-tier-junior',
  QUERY_LIST_TEASER_STYLE = 'cmsetting-query-list-teaser-style',
  NORDIC_BLUE_50_BG = 'nordic-blue-50-bg',
  NORDIC_BLUE_100_BG = 'nordic-blue-100-bg',
  NORDIC_BLUE_900_BG = 'nordic-blue-900-bg',
  HEATHER_BG = 'finnair-heather-bg',
  HEADER_LEVEL_1 = 'header-level-1',
  HEADER_LEVEL_2 = 'header-level-2',
  HEADER_LEVEL_3 = 'header-level-3',
  HEADER_LEVEL_4 = 'header-level-4',
  HEADER_LEVEL_5 = 'header-level-5',
  HEADER_LEVEL_6 = 'header-level-6',
  ASPECT_RATIO_1X1 = 'cmstyle-image-aspect-ratio-1x1',
  ASPECT_RATIO_3X4 = 'cmstyle-image-aspect-ratio-3x4',
  ASPECT_RATIO_4X3 = 'cmstyle-image-aspect-ratio-4x3',
  ASPECT_RATIO_16X9 = 'cmstyle-image-aspect-ratio-16x9',
  ASPECT_RATIO_21X9 = 'cmstyle-image-aspect-ratio-21x9',
  LINK_NOFOLLOW = 'cms-link-nofollow',
  TRAVEL_UPDATE_UPDATED = 'travel-update-updated',
  FONT_HEADING_1 = 'font-heading-1',
  FONT_HEADING_2 = 'font-heading-2',
  FONT_HEADING_3 = 'font-heading-3',
  FONT_HEADING_4 = 'font-heading-4',
  FONT_HEADING_5 = 'font-heading-5',
  TEXT_LINK_LEFT_LIST = 'cmsetting-text-link-left-list',
  QUERY_TEASER_LIST_THIRD = 'cmsetting-query-teaser-list-third',
}

export enum CmsTaxonomyPicture {
  FINNAIR_AVIOS_LOGO = 'finnair-avios-logo', // Deprecated, use keyline versions instead
  FINNAIR_AVIOS_KEYLINE_WHITE = 'avios-keyline-white',
  FINNAIR_AVIOS_KEYLINE_NAVY = 'avios-keyline-navy',
  FINNAIR_ONEWORLD_LOGO = 'finnair-oneworld-logo',
  FINNAIR_ONEWORLD_GEM_EMERALD_LOGO = 'finnair-oneworld-gem-emerald-logo',
  FINNAIR_ONEWORLD_GEM_RUBY_LOGO = 'finnair-oneworld-gem-ruby-logo',
  FINNAIR_ONEWORLD_GEM_SAPPHIRE_LOGO = 'finnair-oneworld-gem-sapphire-logo',
}

export type CmsTaxonomy = CmsTaxonomyGeneral | CmsTaxonomyPicture;

export type HtmlString = string;

export interface CmsPage {
  template: string;
  title: string;
  seoTitle: string;
  teaserTitle: string;
  contentType: CmsContentType;
  teaserText?: string;
  contentContentType?: CmsContentType;
  navigationContentType?: CmsContentType;
  isHeaderVisible?: boolean;
  isFooterVisible?: boolean;
  defaultOriginLocationCode: string | null;
  metaData: CmsPageComponentMetaData[];
  viewTypeName?: PageViewType;
  navigationPathList?: BreadcrumbItem[];
  oneworld?: BreadcrumbItem;
}

interface CmsContentBase {
  contentContentType?: CmsContentType;
  contentId: number;
  contentType: CmsContentType;
  creationDate?: number;
  customPlacement?: string;
  detailText?: HtmlString;
  gridClass?: string;
  jsonUrl?: string;
  localSettings?: any;
  main?: boolean;
  modificationDate?: number;
  navigationContentType?: CmsContentType;
  navigationPathList?: BreadcrumbItem[];
  oneworld?: BreadcrumbItem;
  personalizationParameters?: string[]; // @todo: create types for these strings
  picture?: CmsImageData;
  previewPicture?: CmsImageData;
  related?: CmsContent[];
  segment?: string;
  showForLoginStatus?: LoginStatus;
  showForProfileTiers?: ProfileTier[];
  state?: string;
  subjectTaxonomyTags: CmsTaxonomy[];
  target?: CmsTarget;
  teaserText?: HtmlString;
  teaserTitle?: string;
  template?: string;
  title?: string;
  url?: string;
  version?: number;
  viewType?: CmsViewType;
  viewTypeName: CmsViewType | CmsViewTypeWithGrid | PageViewType | CmsFormType | null;
  masterContentId?: number;
  headerLevel?: number;
  firstMedia?: CmsLink;
}

export interface CmsContent extends CmsContentBase {
  items?: CmsContent[];
}

export interface CmsCollection<T = CmsContent> extends CmsContentBase {
  items: T[];
  contentType: CmsContentType.CMCollection;
}

export type CMSelectionRules<T extends CmsContent = CmsContent> = CmsCollection<T>;

export interface CmsTab {
  title: string;
  picture?: CmsImageData | null;
  iconUrl: string | null;
  content: CmsContent;
}

export interface CmsTabsCollection extends CmsCollection {
  tabs: CmsTab[];
}

export interface CmsTemplate {
  header: (CmsPage | CmsContent | CmsCollection)[];
  main: (CmsContent | CmsCollection)[];
  footer: (CmsContent | CmsCollection)[];
  status?: number;
}

export interface StaticPicture {
  thumbnailSrc?: string;
  fullSizeUrl?: string;
}

export interface CmsVideo extends CmsContent {
  dataUrl: string;
}

export interface CmsPicture extends CmsImageData {
  dataUrl?: never;
}

export interface CmsImageData extends CmsContent {
  /**
   * The alt text for <img/> tags
   */
  alt: string;

  /**
   * URL strings for different
   */
  responsiveImageLinksData?: {
    landscape_ratio16x9: { [widthPx: number]: string };
    landscape_ratio4x3: { [widthPx: number]: string };
    landscape_ratio21x9: { [widthPx: number]: string };
    portrait_ratio1x1: { [widthPx: number]: string };
  };

  data?: string;
  width?: number;
  height?: number;
  disableCropping?: boolean;

  error?: string;
}

export type CmsMedia = CmsPicture | CmsVideo;

export interface CmsDestination extends CmsContent {
  title: string;
  htmlTitle: string;
  timeZoneOffset: number;
  numberOfFlights: string;
  flightDuration: string;
  currency: string;
  localSettings: {
    temperature: number[];
    rainfall: number[];
  };
  picture: CmsImageData;
  defaultLocation: any;
  locationCode?: string;
}

export interface CmsTarget {
  title?: string;
  teaserTitle?: string;
  url: string;
  external: boolean;
  locationCode?: string;
  openInNewTab?: boolean;
  jsonUrl?: string;
}

export interface CmsPromo extends CmsContent {
  picture: CmsImageData;
  target?: CmsTarget;
  teaserTitle: string;
  teaserText: string;
  title: string;
}

export interface CmsOffer extends CmsPromo {
  price: number;
  flag: string;
  booking: string;
  url?: string;
  campaignId?: string;
  defaultLocationCode?: string;
}

export interface CmsTeaser extends CmsContent {
  contentType: CmsContentType.CMTeaser;
  teaserTitle: string;
  teaserText: HtmlString;
  iconUrl?: string;
  picture?: CmsImageData;
  target?: CmsTarget;
  linkText?: string;
  openInNewTab?: boolean;
}

export interface CmsDisclaimer extends CmsTeaser {
  target?: CmsTarget;
  backgroundColor: 'heather-50' | 'nordic-blue-50' | null;
  svgIcon?: SvgLibraryIcon;
}

export interface CmsArticle extends CmsContent {
  anchors?: any[];
  contentType: CmsContentType.CMArticle;
  teaserTitle: string;
  teaserText: HtmlString;
  media: CmsMedia[];
  related: any[];
  detailText: HtmlString;
}

export interface CmsEditorialArticle extends CmsArticle {
  authors: any[];
  externallyDisplayedDate: number;
  detailTextItems?: any[];
}

export interface CmsContentWithDestination extends CmsContent {
  destination: CmsDestination;
}

// TODO: remove/merge to DAPI once server returns these details
export interface CmsProductMeal extends CmsProductBase {
  /** Non-HTML short description of the product */
  teaserTitle: string;
  /** HTML content describing the ingredients. Does not contain title. */
  ingredients: string;
  /** HTML content describing the nutrition facts. Contains title. */
  nutrition: string;
  /** meal categories, e.g. low-lactose. These need to be translated. */
  itemCategory: string[];
  /** meal allergens, e.g. nuts. These need to be translated. */
  allergens: string[];
}

export interface CmsMenuFoodItem extends CmsContent {
  teaserTitle: string;
  ingredients: string;
  nutrition: string;
  itemCategory: string[];
  allergens: string[];
}

export interface CmsPlaceholder extends CmsContent {
  contentType: CmsContentType.CMPlaceholder;
  id: string;
}

export interface CmsProductWithName extends CmsProductBase {
  /** product description */
  productName: string;
}

export type CmsProduct = CmsProductMeal | CmsProductWithName;

export interface CmsProductBase {
  /** Same as search code */
  productCode: string;
  /** product description */
  title: string;
  /** Responsive images */
  media: CmsMedia[];
}

export interface CmsQueryListItem extends CmsContent {
  externallyDisplayedDate: number;
  subjectTaxonomyTags: CmsTaxonomy[];
  teaserText: HtmlString;
  teaserTitle: string;
  url: string;
  media: CmsMedia[];
}

export interface CmsQueryList extends CmsContent {
  items: CmsQueryListItem[];
  numberOfPages?: number;
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum CmsDocumentType {
  MEAL = 'FIMenuFoodItem',
  SPECIALDIET = 'FIMenuFoodItem',
  WIFI = 'FIProduct',
  LOUNGE = 'FIProduct',
  COVER = 'FIProduct',
  TRAVELCOMFORT = 'FIProduct',
}
/* eslint-enable @typescript-eslint/no-duplicate-enum-values */

export interface CmsLink {
  contentId: number;
  jsonUrl: string;
}
