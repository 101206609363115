import { Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { EMPTY, Observable, catchError, combineLatest, filter, map } from 'rxjs';

import { hasUnitPrices } from '@fcom/common/utils';
import { SentryLogger } from '@fcom/core/services';
import {
  FinnairAmount,
  FinnairCharge,
  FinnairPassengerCode,
  FinnairPassengerItem,
  FinnairPrice,
  FinnairTotalPricesDetails,
} from '@fcom/dapi/api/models';
import { Amount, PriceService } from '@fcom/dapi/interfaces';
import { asPaxTypeKey } from '@fcom/dapi/utils';
import { ModalButtons } from '@fcom/ui-components';
import { ElementActions, ElementTypes } from '@fcom/common/interfaces';
import { GtmService } from '@fcom/common/gtm';

import { getPassengerServices, hasPassengers } from '../../utils/order.utils';

export interface BreakdownModel extends FinnairPassengerItem {
  prices: FinnairPrice;
  services: PriceService[];
  total: FinnairAmount;
}

@Component({
  selector: 'fin-price-breakdown-renewed',
  templateUrl: './price-breakdown-renewed.component.html',
  styleUrls: ['./price-breakdown-renewed.component.scss'],
})
export class PriceBreakdownRenewedComponent implements OnInit {
  public readonly ModalButtons = ModalButtons;
  public readonly asPaxTypeKey = asPaxTypeKey;
  public readonly FinnairPassengerCode = FinnairPassengerCode;
  public readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  prices$: Observable<FinnairTotalPricesDetails>;
  @Input()
  passengers$: Observable<FinnairPassengerItem[]>;
  @Input()
  showBaseFare = true;
  @Input()
  showTotalTax = true;
  @Input()
  showPoints = false;
  @Input()
  expandOpen = true;

  breakdowns$: Observable<BreakdownModel[]>;
  totalPrice$: Observable<Amount>;
  taxModalOpen = false;
  taxModalData: { totalTaxes: FinnairAmount };
  summary$: Observable<PriceService[]>;
  summaryModalOpen: boolean;
  summaryModalData: { prices: FinnairTotalPricesDetails };
  totalTaxes$: Observable<FinnairCharge[]>;

  ENVIRONMENTAL_SURCHARGE = 'environmentalSurcharge';

  constructor(
    private sentryLogger: SentryLogger,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.breakdowns$ = combineLatest([this.passengers$, this.prices$]).pipe(
      filter(([passengers, prices]) => hasPassengers(passengers) && hasUnitPrices(prices)),
      map(([passengers, prices]) =>
        passengers
          .filter((passenger) => parseFloat(prices.total.totalPerPax?.[passenger.id]?.totalAmount.amount) > 0)
          .map((passenger: FinnairPassengerItem) => ({
            ...passenger,
            prices: prices.flight?.totalPerPax?.[passenger.id] || prices.services?.totalPerPax?.[passenger.id],
            services: getPassengerServices(prices.services?.totalPerCategory, passenger),
            total: prices.total.totalPerPax?.[passenger.id]?.totalAmount,
          }))
      ),
      catchError((e: unknown) => {
        this.sentryLogger.error('Exception in handling prices of passengers', e);
        return EMPTY;
      })
    );

    this.summary$ = combineLatest([this.passengers$, this.prices$]).pipe(
      filter(([passengers, prices]) => hasPassengers(passengers) && hasUnitPrices(prices)),
      map(([passengers, prices]) => {
        const summary = passengers.reduce<{ [key: string]: PriceService }>(
          (summaryAcc, passenger: FinnairPassengerItem) => {
            const passengerSummary = getPassengerServices(prices.services?.totalPerCategory, passenger);

            passengerSummary.forEach((service) => {
              if (summaryAcc[service.id]) {
                const totalAmount = parseFloat(summaryAcc[service.id].total.amount) + parseFloat(service.total.amount);
                summaryAcc[service.id] = {
                  ...summaryAcc[service.id],
                  total: {
                    ...summaryAcc[service.id].total,
                    amount: totalAmount.toString(),
                  },
                  count: service.count + summaryAcc[service.id].count,
                };
              } else {
                summaryAcc[service.id] = { ...service };
              }
            });
            return summaryAcc;
          },
          {}
        );

        return Object.values(summary);
      })
    );

    this.totalTaxes$ = combineLatest([this.passengers$, this.prices$]).pipe(
      filter(([passengers, prices]) => hasPassengers(passengers) && hasUnitPrices(prices)),
      map(([passengers, prices]) => {
        const totalTaxes = passengers.reduce<{ [key: string]: FinnairCharge }>(
          (taxAcc, passenger: FinnairPassengerItem) => {
            const passengerTaxes = prices.total.totalPerPax[passenger.id]?.taxes;

            passengerTaxes?.forEach((tax) => {
              if (taxAcc[tax.code]) {
                taxAcc[tax.code].amount = (parseFloat(taxAcc[tax.code].amount) + parseFloat(tax.amount)).toString();
              } else {
                taxAcc[tax.code] = { ...tax };
              }
            });

            const passengerEnvironmentalSurcharge = prices.flight?.totalPerPax?.[passenger.id]?.environmentalSurcharge;
            const surchargeAmount = parseFloat(passengerEnvironmentalSurcharge?.amount);

            if (!surchargeAmount) {
              return taxAcc;
            }

            taxAcc[this.ENVIRONMENTAL_SURCHARGE] = taxAcc[this.ENVIRONMENTAL_SURCHARGE]
              ? {
                  ...taxAcc[this.ENVIRONMENTAL_SURCHARGE],
                  amount: (parseFloat(taxAcc[this.ENVIRONMENTAL_SURCHARGE].amount) + surchargeAmount).toString(),
                }
              : {
                  ...passengerEnvironmentalSurcharge,
                  code: this.ENVIRONMENTAL_SURCHARGE,
                };

            return taxAcc;
          },
          {}
        );

        return Object.values(totalTaxes);
      })
    );
  }

  openTaxModal(event, totalTaxes: FinnairAmount) {
    event.stopPropagation();

    this.taxModalOpen = true;
    this.taxModalData = { totalTaxes };

    this.gtmService.trackElement(
      'taxes-fees-charges-modal',
      'booking-flow_purchase-review',
      ElementTypes.BUTTON,
      '',
      ElementActions.CLICK
    );
  }

  openSummaryModal(event, prices: FinnairTotalPricesDetails) {
    event.stopPropagation();

    this.summaryModalOpen = true;
    this.summaryModalData = {
      prices,
    };

    this.gtmService.trackElement(
      'detailed-breakdown-modal',
      'booking-flow_purchase-review',
      ElementTypes.BUTTON,
      '',
      ElementActions.CLICK
    );
  }

  closeSummaryModal() {
    this.summaryModalData = null;
  }

  closeTaxModal() {
    this.taxModalData = null;
  }
}
