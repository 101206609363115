import { createReducer, on } from '@ngrx/store';

import { GlobalBookingTravelClass, TripType, setIn, updateIn } from '@fcom/core';

import { GlobalBookingActions } from '../actions';
import { GlobalBookingFlight, GlobalBookingState } from '../store.interface';
import { MAX_PAX_AMOUNT, MIN_LEAD_PAX_AMOUNT } from '../constants';

export const initialState: GlobalBookingState = {
  tripType: TripType.RETURN,
  travelClasses: {
    selected: GlobalBookingTravelClass.MIXED,
    availability: {
      MIXED: true,
      ECONOMY: true,
      ECOPREMIUM: false,
      BUSINESS: true,
      FIRST: false,
    },
  },
  origin: undefined,
  routeType: undefined,
  paxAmount: {
    adults: 1,
    c15s: 0,
    children: 0,
    infants: 0,
  },
  flights: [{ origin: undefined, destination: undefined, departureDate: undefined }],
};

const globalBooking = createReducer(
  initialState,
  on(GlobalBookingActions.setTripType, (state, { tripType }) =>
    tripType === state.tripType
      ? state
      : {
          ...state,
          tripType,
          flights:
            tripType === TripType.ONEWAY
              ? [state.flights[0]]
              : tripType === TripType.RETURN
                ? [
                    state.flights[0],
                    {
                      ...state.flights[0],
                      origin: state.flights?.[0]?.destination,
                      destination: state.flights?.[0]?.origin,
                      departureDate: undefined,
                    },
                  ]
                : [state.flights[0], {}],
        }
  ),
  on(GlobalBookingActions.setTravelClass, (state, { travelClass }) => ({
    ...state,
    travelClasses: {
      ...state.travelClasses,
      selected: travelClass === GlobalBookingTravelClass.FIRST ? GlobalBookingTravelClass.BUSINESS : travelClass,
    },
  })),
  on(GlobalBookingActions.setOrigin, (state, { origin }) => ({
    ...state,
    origin,
  })),
  on(GlobalBookingActions.setRouteType, (state, { routeType }) => ({
    ...state,
    routeType,
  })),
  on(GlobalBookingActions.setFlightDate, (state, { dates, index }) => ({
    ...state,
    flights:
      state.tripType === TripType.RETURN
        ? [
            { ...state.flights[0], departureDate: dates.departureDate },
            { ...state.flights[1], departureDate: dates.returnDate },
          ]
        : [
            ...state.flights.slice(0, index),
            { ...state.flights[index], departureDate: dates.departureDate },
            ...state.flights.slice(index + 1),
          ],
  })),
  on(GlobalBookingActions.setPaxAmount, (state, { paxAmount }) => ({
    ...state,
    paxAmount,
  })),
  on(GlobalBookingActions.increasePaxAmountField, (state, { field, increment }) =>
    updatePaxAmountField(state, field, (old) => old + increment)
  ),
  on(GlobalBookingActions.decreasePaxAmountField, (state, { field, decrement }) =>
    updatePaxAmountField(state, field, (old) => Math.max(old - decrement, 0))
  ),
  on(GlobalBookingActions.setDiscountCode, (state, { discountCode }) => ({
    ...state,
    discountCode,
  })),
  on(GlobalBookingActions.setSelection, (state, { selection }) => ({
    ...state,
    paxAmount: selection.paxAmount,
    flights: selection.flights,
    tripType: selection.tripType,
    travelClasses: { ...state.travelClasses, selected: selection.travelClass },
    discountCode: selection.discountCode ?? undefined,
  })),
  on(GlobalBookingActions.resetSelection, (state) => ({
    ...state,
    paxAmount: initialState.paxAmount,
    flights: [{ origin: state.flights[0]?.origin }],
    tripType: initialState.tripType,
    travelClasses: initialState.travelClasses,
    discountCode: initialState.discountCode,
  })),
  on(GlobalBookingActions.updateSelection, (state, { selection }) => ({
    ...state,
    ...selection,
  })),
  // This was moved here from booking reducer, is it even relevant?
  // Should we move PaymentActions and PaymentStatus under common?
  // Data Clean-ups: When Payment verified
  on(GlobalBookingActions.paymentVerified, (state) => ({
    ...state,
    flights: [{ origin: state.flights[0].origin }],
  })),
  on(GlobalBookingActions.setFlights, (state, { flights }) => {
    return {
      ...state,
      flights,
    };
  }),
  on(GlobalBookingActions.updateFlight, (state, { flight, index }) => ({
    ...state,
    flights: updateFlights(state, flight, index),
  })),
  on(GlobalBookingActions.addFlight, (state) => ({
    ...state,
    flights: [
      ...state.flights,
      {
        origin: state.flights[state.flights.length - 1]?.destination,
        destination: undefined,
        departureDate: undefined,
      },
    ],
  })),
  on(GlobalBookingActions.removeFlight, (state, { index }) => ({
    ...state,
    flights: state.flights.filter((_f, i) => i !== index),
  })),
  on(GlobalBookingActions.setAvailableClasses, (state, { availability }) => ({
    ...state,
    travelClasses: {
      ...state.travelClasses,
      availability: {
        ...Object.keys(initialState.travelClasses.availability).reduce(
          (availableClasses, travelClass: GlobalBookingTravelClass) => {
            availableClasses[travelClass] = false;
            return availableClasses;
          },
          {}
        ),
        ...availability,
      },
    },
  }))
);

const updatePaxAmountField = (state: GlobalBookingState, field: string, mutator: (oldValue: number) => number) => {
  const newState = disallowMoreInfantsThanAdults(updateIn(state, ['paxAmount', field], mutator));
  const newLeadPaxAmount = newState?.paxAmount?.adults;
  const newTotal = newLeadPaxAmount + state?.paxAmount?.c15s + state?.paxAmount?.children;
  if (newTotal > MAX_PAX_AMOUNT || newLeadPaxAmount < MIN_LEAD_PAX_AMOUNT) {
    return state;
  }
  return newState;
};

const disallowMoreInfantsThanAdults = (state: GlobalBookingState) => {
  const numAdults = state.paxAmount.adults;
  if (state.paxAmount.infants > numAdults) {
    return setIn(state, 'paxAmount.infants', numAdults);
  }
  return state;
};

export const globalBookingReducer = (
  state: GlobalBookingState,
  action: GlobalBookingActions.GlobalBookingActionsUnion
): GlobalBookingState => {
  return globalBooking(state, action);
};

const updateFlights = (
  state: GlobalBookingState,
  updatedFlightData: GlobalBookingFlight,
  indexFlightNeedUpdate: number
): GlobalBookingFlight[] => {
  switch (state.tripType) {
    case TripType.MULTICITY:
      return state.flights.map((flight, index) => {
        if (index === indexFlightNeedUpdate) {
          return { ...flight, ...updatedFlightData };
        }

        if (index === indexFlightNeedUpdate + 1) {
          return !flight.origin ? { ...flight, origin: updatedFlightData.destination } : flight;
        }

        return flight;
      });

    case TripType.RETURN:
      return [
        { ...state.flights[0], ...updatedFlightData },
        {
          ...state.flights[1],
          origin: updatedFlightData.destination,
          destination: updatedFlightData.origin,
        },
      ];

    default:
      return state.flights.map((flight, index) =>
        index === indexFlightNeedUpdate ? { ...flight, ...updatedFlightData } : flight
      );
  }
};
