import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  type OnChanges,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { Validators, type AbstractControl, type FormControlStatus, type UntypedFormGroup } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, Subscription, type Observable, type Subject } from 'rxjs';

import { SentryLogger } from '@fcom/core/services';
import { stopPropagation, unsubscribe } from '@fcom/core/utils';
import { LanguageService } from '@fcom/ui-translate';

import { ButtonMode } from '../../buttons';
import { IconPosition } from '../../icons';
import { AttachmentError, AttachmentStatus, type Attachment, type FileUploaderI18n } from '../../interfaces';
import { ATTACHMENT_ALLOWED_FILE_EXTENSIONS } from '../enums';
import { type AttachmentResponse } from '../interfaces';
import {
  attachmentExtensionValidator,
  attachmentFileSizeValidator,
  attachmentStatusValidator,
} from './file-uploader-validators';

const ONE_MB_IN_BYTES = 1048576;

@Component({
  selector: 'fcom-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnChanges, OnInit, OnDestroy {
  readonly ButtonMode = ButtonMode;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() allowedFileTypes: string[] = ATTACHMENT_ALLOWED_FILE_EXTENSIONS;
  @Input() customAttachLabel = '';
  @Input() customRequiredLabel = '';
  @Input() disabled = false;
  @Input() id: string;
  @Input() maxSizeInBytes: number;
  @Input() multiple: boolean;
  @Input() uploadService: (file: File) => Observable<AttachmentResponse>;
  @Input({ required: true }) controlName: string;
  @Input({ required: true }) i18n: FileUploaderI18n;
  @Input({ required: true }) parentForm: UntypedFormGroup;

  @Output() fileDeleted: EventEmitter<Attachment> = new EventEmitter<Attachment>();
  @Output() filesUpdated: EventEmitter<Attachment[]> = new EventEmitter<Attachment[]>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  dragInProgress$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  idOrControlName: string;
  maxSizeMb: number | undefined;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    private sentryLogger: SentryLogger
  ) {}

  /**
   * Transforms an array of allowed file types to a string
   * for the `accept` property of the file input.
   *
   * @example ['gif', 'jpg', 'png']
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept MDN documentation} for more information.
   * @returns {string} `'.gif,.jpg,.png'`
   */
  get acceptValues(): string {
    return this.allowedFileTypes.length ? `.${this.allowedFileTypes.join(',.')}` : '';
  }

  get attachments(): Attachment[] {
    return this.ctrlField.value || [];
  }

  get containsErrors(): boolean {
    const ignoreErrorStatuses: AttachmentError[] = [AttachmentError.UPLOADING];
    return (
      !this.disabled &&
      this.ctrlField.touched &&
      Object.keys(this.ctrlField.errors || {}).filter(
        (key: AttachmentError): boolean => !ignoreErrorStatuses.includes(key)
      ).length > 0
    );
  }

  get ctrlField(): AbstractControl<Attachment[]> {
    return this.parentForm.get(this.controlName);
  }

  get fieldDisabled(): boolean {
    return this.disabled || this.ctrlField.disabled || (!this.multiple && this.attachments.length === 1);
  }

  get fieldRequired(): boolean {
    return this.ctrlField.hasValidator(Validators.required);
  }

  /**
   * Formats the allowed file extensions as a localized string
   * separated by a comma and the `and` word for the final entry.
   * @example ['gif', 'jpg', 'png']
   * @external Intl.ListFormat
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat MDN documentation} for more information.
   * @return {string} `'gif, jpg, and png'`
   */
  get filesSupported(): string {
    const options: Intl.ListFormatOptions = {
      style: 'long',
      type: 'conjunction',
    };
    return new Intl.ListFormat(this.languageService.langKeyValue || 'en', options).format(this.allowedFileTypes);
  }

  get isUploading(): boolean {
    return this.attachments.some((a: Attachment): boolean => a.status === AttachmentStatus.UPLOADING);
  }

  get labelAttach(): string {
    return this.customAttachLabel || (this.multiple ? this.i18n.attachFiles : this.i18n.attachFile);
  }

  get labelRequired(): string {
    return this.customRequiredLabel || (this.multiple ? this.i18n.errors.requiredMultiple : this.i18n.errors.required);
  }

  ngOnChanges(): void {
    this.initValidators();
  }

  ngOnInit(): void {
    this.idOrControlName = this.id || this.controlName;
    this.maxSizeMb = this.maxSizeInBytes ? +(this.maxSizeInBytes / ONE_MB_IN_BYTES).toFixed(1) : undefined;

    this.initValidators();

    this.subscriptions.add(
      this.ctrlField.valueChanges.subscribe((updatedAttachments: Attachment[]): void => {
        if (!updatedAttachments) {
          return;
        }
        this.filesUpdated.emit(updatedAttachments);
        if (this.uploadService) {
          this.uploadAttachments(updatedAttachments.filter((attachment: Attachment): boolean => !attachment.status));
        }
        this.ctrlField.markAsTouched();
        this.ctrlField.updateValueAndValidity({ emitEvent: false });
        this.cdr.markForCheck();
      })
    );

    this.subscriptions.add(
      this.parentForm.statusChanges.subscribe((value: FormControlStatus): void => {
        if ('INVALID' === value && this.ctrlField.touched) {
          this.ctrlField.updateValueAndValidity({ onlySelf: true });
        }
      })
    );

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  deleteAttachment(attachment: Attachment): void {
    if (!attachment) {
      return;
    }
    this.ctrlField.setValue(
      this.ctrlField.value.filter((a: Attachment): boolean => a.file.name !== attachment.file.name)
    );
    this.fileDeleted.emit(attachment);
  }

  onDragEnter(enable: boolean): void {
    if (this.fieldDisabled) {
      return;
    }
    this.dragInProgress$.next(enable);
  }

  onDragOver(event: DragEvent): void {
    stopPropagation(event);
    if (this.fieldDisabled) {
      event.dataTransfer.dropEffect = 'none';
      event.dataTransfer.effectAllowed = 'none';
    }
  }

  @HostListener('cancel', ['$event'])
  onFileCancel(event: Event): void {
    // Prevent triggering error when no files are returned from the input
    // and some already exist in the attachment list
    if (this.attachments.length) {
      stopPropagation(event);
      return;
    }
    this.ctrlField.markAsTouched();
  }

  @HostListener('change', ['$event'])
  onFileChange(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const validatedAttachments: Attachment[] = Array.from(target.files).map(
      (f: File): Attachment => this.validateFile(f)
    );
    if (validatedAttachments.length) {
      stopPropagation(event);
      this.ctrlField.setValue([...this.attachments, ...validatedAttachments]);
    }
  }

  onFileDrop(event: DragEvent): void {
    if (this.fieldDisabled) {
      return;
    }
    stopPropagation(event);
    const files: FileList = event?.dataTransfer?.files;
    this.dragInProgress$.next(false);
    if (files) {
      const validatedAttachments: Attachment[] = Array.from(files).map(
        (file: File): Attachment => this.validateFile(file)
      );
      this.ctrlField.setValue([...this.attachments, ...validatedAttachments]);
    }
  }

  /**
   * Fixes bug on Chrome where the cancel event gets fired instead of the change event.
   * @see {@link https://stackoverflow.com/q/42355858}
   */
  @HostListener('click', ['$event'])
  onClick(): void {
    this.fileInput.nativeElement.value = null;
  }

  openFileSelector(): void {
    if (this.disabled) {
      return;
    }
    this.fileInput.nativeElement.click();
  }

  private errorCallback(attachment: Attachment, error: unknown): void {
    this.updateAttachmentDetails(attachment, { status: AttachmentStatus.ERROR });
    this.sentryLogger.warn('Failed to upload attachment:', { error });
  }

  private fileEquals(a: File, b: File): boolean {
    return a.name === b.name && a.size === b.size && a.lastModified === b.lastModified;
  }

  private initValidators(): void {
    this.ctrlField.addValidators(attachmentStatusValidator());

    if (this.allowedFileTypes.length) {
      this.ctrlField.addValidators(attachmentExtensionValidator(this.allowedFileTypes));
    }

    if (this.maxSizeInBytes) {
      this.ctrlField.addValidators(attachmentFileSizeValidator(this.maxSizeInBytes));
    }
  }

  private uploadAttachment(attachment: Attachment): void {
    this.subscriptions.add(
      this.uploadService(attachment.file).subscribe({
        next: (response: AttachmentResponse): void => this.uploadCallback(attachment, response),
        error: (error: unknown): void => this.errorCallback(attachment, error),
      })
    );
  }

  private updateAttachmentDetails(attachment: Attachment, details: Partial<Attachment>): void {
    if (!attachment) {
      return;
    }
    const updatedAttachments: Attachment[] = this.attachments.map(
      (a: Attachment): Attachment => (this.fileEquals(a.file, attachment.file) ? { ...a, ...details } : a)
    );
    this.ctrlField.setValue(updatedAttachments);
  }

  private uploadAttachments(attachmentsToUpload: Attachment[]): void {
    if (!attachmentsToUpload.length) {
      return;
    }
    const updatedAttachments: Attachment[] = this.attachments.map(
      (attachment: Attachment): Attachment =>
        attachment.status ? attachment : { ...attachment, status: AttachmentStatus.UPLOADING }
    );
    this.ctrlField.setValue(updatedAttachments);
    for (const attachment of attachmentsToUpload) {
      this.uploadAttachment(attachment);
    }
  }

  private uploadCallback(attachment: Attachment, response: AttachmentResponse): void {
    const fileId = response?.attachmentId;
    if (fileId) {
      this.updateAttachmentDetails(attachment, { status: AttachmentStatus.READY, fileId });
    } else {
      this.updateAttachmentDetails(attachment, { status: AttachmentStatus.ERROR });
    }
  }

  private validateFile(file: File): Attachment {
    const attachment: Attachment = { file };
    const fileExtension: string = file.name.split('.').pop();
    if (!this.allowedFileTypes.includes('*') && !this.allowedFileTypes.includes(fileExtension)) {
      attachment.status = AttachmentStatus.ERROR;
      attachment.error = this.i18n.errors.invalidFileType;
    }
    if (file.size > this.maxSizeInBytes) {
      attachment.status = AttachmentStatus.ERROR;
      attachment.error = this.i18n.errors.invalidFileSize;
    }
    return attachment;
  }
}
