import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UiTranslateModule } from '@fcom/ui-translate';
import { ButtonModule, IconsModule, ModalModule } from '@fcom/ui-components';

import { CmsModule } from '../../cms/components';
import { ComponentsModule } from '../components';
import { PipesModule } from '../pipes';
import { MarketingPreferencesComponent } from './components';
import { ConsentService } from './services/consent.service';
import { DirectiveModule } from '../directives';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    ComponentsModule,
    CmsModule,
    FormsModule,
    UiTranslateModule,
    IconsModule,
    ModalModule,
    ButtonModule,
    DirectiveModule,
  ],
  declarations: [MarketingPreferencesComponent],
  exports: [MarketingPreferencesComponent],
})
export class MarketingPreferencesModule {
  static forRoot(): ModuleWithProviders<MarketingPreferencesModule> {
    return {
      ngModule: MarketingPreferencesModule,
      providers: [ConsentService],
    };
  }
}
